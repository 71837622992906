import { Recipe, UnsavedRecipe } from '../@aligre/types'
import {
  DeleteResponse,
  RecipeRequestBody,
  RecipeResponse,
  UnsavedRecipeRequestBody,
} from '../@aligre/types/api'
import apiFetch from './apiFetch'

export async function createRecipe(recipe: UnsavedRecipe) {
  return await apiFetch<UnsavedRecipeRequestBody, RecipeResponse>(
    'recipes/',
    'POST',
    { recipe }
  )
}

export async function updateRecipe(recipe: Recipe) {
  return await apiFetch<RecipeRequestBody, RecipeResponse>(
    `recipes/${recipe._id}`,
    'PUT',
    { recipe }
  )
}

export async function deleteRecipe(
  recipe: Recipe
): Promise<{ message: string }> {
  return await apiFetch<void, DeleteResponse>(`recipes/${recipe._id}`, 'DELETE')
}
