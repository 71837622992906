import { IngredientLineWithoutMainIngredient } from '../../types'
import { PREP_NOT_COMMUNICATED } from './utils/prep'
import { acceptedNumbers, QUANTITY_NOT_COMMUNICATED } from './utils/quantity'
import { getSize, Sizes, sizeWords, SIZE_NOT_COMMUNICATED } from './utils/size'
import {
  acceptedUnityRe,
  PIECE_UNITY,
  singularizeUnity,
  UNITY_NOT_COMMUNICATED,
} from './utils/unity'

/**
 * @internal
 */
const regExpWithUnityStr = `^([${acceptedNumbers}]+ )?(${sizeWords
  .map((w) => `${w} `)
  .join('|')})?(${acceptedUnityRe.join('|')}) +(d'|de )?(.*)$`

/**
 * @internal
 */
const regExpWithoutUnityStr = `^([${acceptedNumbers}]+ )?( d'| de )?(.*)$`

/**
 * @internal
 */

const regExpWithUnity = new RegExp(`${regExpWithUnityStr}`)
/**
 * @internal
 */
const regExpWithoutUnity = new RegExp(`${regExpWithoutUnityStr}`)

/**
 * @internal
 */
const regExpSize = new RegExp(`(?: |^)(${sizeWords.join('|')})(?: |$)`)

/**
 * @internal
 */
const getValidCommaIndex = (str: string): number | undefined => {
  let start = str.indexOf('(')
  let end = str.indexOf(')')
  if (start > -1) {
    if (end < start || end === -1) {
      throw Error('Ingredient Line Parenthesis are invalid')
    }
  } else {
    start = str.length
    end = str.length
  }

  const index = str.indexOf(', ')
  if (index < start || index > end) {
    return index
  }
}

/**
 * Break down an ingredient line string into chunks
 * @param line
 * @category ingredientLine
 */
const parseIngredientLine = (
  line: string
): IngredientLineWithoutMainIngredient => {
  let quantity = QUANTITY_NOT_COMMUNICATED
  let unity = UNITY_NOT_COMMUNICATED
  let size = SIZE_NOT_COMMUNICATED
  let prep = PREP_NOT_COMMUNICATED
  let ingredient = ''
  let ingPrep = ''
  const matchesWithUnity = line.match(regExpWithUnity)
  const matchesWithoutUnity = line.match(regExpWithoutUnity)

  if (matchesWithUnity) {
    const [
      ,
      quantityStr1,
      wordBeforeUnity,
      unityStr,
      ,
      strRest,
    ] = matchesWithUnity
    if (quantityStr1) {
      quantity = quantityStr1.trim()
    }
    if (wordBeforeUnity) {
      size = getSize(wordBeforeUnity.trim())
    }
    if (unityStr) {
      unity = singularizeUnity(unityStr)
    }
    ingPrep = strRest.trim()
  } else if (matchesWithoutUnity) {
    const [, quantityStr1, , strRest] = matchesWithoutUnity
    if (quantityStr1) {
      quantity = quantityStr1.trim()
      unity = PIECE_UNITY
    }
    ingPrep = strRest.trim()
  } else {
    ingPrep = line.trim()
  }

  let commaIndex = getValidCommaIndex(ingPrep)

  if (commaIndex && commaIndex > 0 && commaIndex !== Infinity) {
    ingredient = ingPrep.substring(0, commaIndex).trim()
    prep = ingPrep.substring(commaIndex).replace(/^,/, '').trim()
  } else {
    ingredient = ingPrep.trim()
  }

  if (
    size === SIZE_NOT_COMMUNICATED &&
    ingredient.indexOf('piment') === -1 &&
    ingredient.indexOf(' pois') === -1 &&
    ingredient.indexOf(' semoule') === -1 &&
    ingredient.indexOf('petit salé') === -1
  ) {
    const sizeMatches = ingredient.match(regExpSize)
    if (sizeMatches) {
      ingredient = ingredient.replace(
        sizeMatches[0],
        sizeMatches[0].trim().length === sizeMatches[0].length - 2 ? ' ' : ''
      )
      size = getSize(sizeMatches[0].trim())
    }
  }

  if (size === Sizes.L && line.indexOf(' gros sel') !== -1) {
    ingredient = 'gros sel'
  }

  return {
    ingredient,
    unity,
    quantity,
    prep,
  }
}

export default parseIngredientLine
