export * from './aisles'
export * from './categories'
export * from './groceryList'
export * from './ingredientLines'
export * from './mainIngredients'
export * from './pantry'
export * from './pin'
export * from './ratings'
export * from './recipes'
export * from './season'
export * from './sources'
export * from './users'

/**
 * Check if undefined. Usage `.filter(notUndefined)`
 * @category utils
 */
export function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined
}

/**
 * Filter all undefined values
 * @category utils
 */
export function filterUndefined<T>(ts: (T | undefined)[]): T[] {
  return ts.filter(notUndefined)
}
