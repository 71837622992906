import {
  CategoryDict,
  MainIngredientDict,
  NoRatingScore,
  RatingScore,
  Recipe,
  RecipeGroceryListDict,
  RecipeRatingDict,
  SourceDict,
  UserPinDict,
} from '../../types'

/**
 * @internal
 */
const CDN_ENDPOINT = '/'

/**
 * Given an id, get the recipe
 * @category recipe
 */
export const findRecipeById = (
  recipeId: string,
  allRecipes: Recipe[]
): Recipe | undefined =>
  allRecipes.find((recipe: Recipe) => recipe._id === recipeId)

/**
 * Get the recipe source name
 * @category recipe
 */
export const getRecipeSource = (
  recipe: Recipe,
  allSources: SourceDict
): string => allSources[recipe.source]

/**
 * Get the recipe category names
 * @category recipe
 */
export const getRecipeCategories = (
  recipe: Recipe,
  allCategories: CategoryDict
): string[] => recipe.categories.map((categoryId) => allCategories[categoryId])

/**
 * Get the recipe rating
 * @category recipe
 */
export const getRecipeRating = (
  recipe: Recipe,
  ratings: RecipeRatingDict
): RatingScore | NoRatingScore => {
  const noRating = 0 as NoRatingScore
  return recipe._id && ratings[recipe._id]
    ? ratings[recipe._id].score
    : noRating
}

/**
 * Get recipe picture url
 * @category recipe
 */
export const getRecipePictureUrl = (recipe: Recipe): string =>
  recipe.photo
    ? `${CDN_ENDPOINT}img/${recipe._id}.jpg`
    : `${CDN_ENDPOINT}recipe.png`

/**
 * Is recipe pinned by user?
 * @category pin
 */
export const isRecipePinned = (
  recipe: Recipe,
  pinned: UserPinDict
): boolean => {
  return pinned[recipe._id] || false
}

/**
 * Is there a grocery list based on a given recipe
 * @category groceryList
 */
export const isRecipeInGroceryList = (
  recipe: Recipe,
  groceryListItems: RecipeGroceryListDict
): boolean => {
  return Boolean(groceryListItems[recipe._id])
}

/**
 * Get for a given recipe, if nested, the main ingredient Id associated with it
 * @category recipe
 */
export const getRecipeAssociatedMainIngredientIdIfNested = (
  recipeId: string,
  allMainIngredients: MainIngredientDict
): string | null => {
  const mainIngredient = Object.values(allMainIngredients).find(
    (i) => i.recipe && i.recipe === recipeId
  )
  if (mainIngredient) {
    return mainIngredient._id
  }
  return null
}
