import AppBar from '@material-ui/core/AppBar'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Kitchen from '@material-ui/icons/Kitchen'
import LocalGroceryStore from '@material-ui/icons/LocalGroceryStore'
import Menu from '@material-ui/icons/Menu'
import MenuBook from '@material-ui/icons/MenuBook'
import Nature from '@material-ui/icons/Nature'
import Person from '@material-ui/icons/Person'
import PersonOutline from '@material-ui/icons/PersonOutline'
import PlusOne from '@material-ui/icons/PlusOne'
import Poll from '@material-ui/icons/Poll'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { isRecipePinned } from '../@aligre/lib/recipes'
import { isAdmin } from '../@aligre/lib/users'
import RecipeList from '../components/RecipeList'
import { mayBeAuthenticated } from '../services/apiFetch'
import logout from '../services/logout'
import { RecipeContext } from '../state/recipes/RecipeProvider'
import { UserContext } from '../state/user/UserProvider'

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  grow: {
    flexGrow: 1,
  },
}))

interface AppBarAndDrawerProps extends RouteComponentProps {}
function AppBarAndDrawer(props: AppBarAndDrawerProps) {
  console.count('[render] AppBarAndDrawer')
  const userContext = useContext(UserContext)
  const { pinned } = userContext
  const recipeContext = useContext(RecipeContext)
  const { allRecipes } = recipeContext
  const classes = useStyles()
  const [state, setState] = useState({
    left: false,
  })

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setState({ ...state, left: open })
  }

  const pinnedRecipes = useMemo(
    () => allRecipes.filter((recipe) => isRecipePinned(recipe, pinned)),
    [allRecipes, pinned]
  )

  const onRecipeClick = useCallback(
    (recipe) => {
      props.history.push(`/recipe/${recipe._id}`)
    },
    [props.history]
  )

  const getRecipeSecondaryText = useCallback(() => '', [])

  return (
    <>
      <AppBar position='fixed'>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={toggleDrawer(true)}>
            <Menu />
          </IconButton>
          <Typography variant='h6' noWrap>
            Aligre
          </Typography>
          <div className={classes.grow} />
          <IconButton
            edge='end'
            color='inherit'
            aria-label='login'
            onClick={logout}
            href='/login'>
            {mayBeAuthenticated() ? <Person /> : <PersonOutline />}
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer open={state.left} onClose={toggleDrawer(false)}>
        <div
          className={classes.list}
          role='presentation'
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}>
          <List>
            <ListItem component={Link} button key='Search' to='/'>
              <ListItemIcon>
                <MenuBook />
              </ListItemIcon>
              <ListItemText primary='Search' />
            </ListItem>
            <ListItem
              component={Link}
              button
              key='Seasonal Produces'
              to='/seasonal'>
              <ListItemIcon>
                <Nature />
              </ListItemIcon>
              <ListItemText primary='Seasonal Produces' />
            </ListItem>
            <ListItem component={Link} button key='Pantry' to='/pantry'>
              <ListItemIcon>
                <Kitchen />
              </ListItemIcon>
              <ListItemText primary='Pantry' />
            </ListItem>
            <ListItem component={Link} button key='Grocery' to='/grocery'>
              <ListItemIcon>
                <LocalGroceryStore />
              </ListItemIcon>
              <ListItemText primary='Grocery' />
            </ListItem>

            {isAdmin(userContext) && (
              <>
                <Divider />
                <ListItem
                  component={Link}
                  button
                  key='Create Recipe'
                  to='/admin/recipe/create'>
                  <ListItemIcon>
                    <PlusOne />
                  </ListItemIcon>
                  <ListItemText primary='Create Recipe' />
                </ListItem>
                <ListItem component={Link} button key='Preps' to='/admin/preps'>
                  <ListItemIcon>
                    <Poll />
                  </ListItemIcon>
                  <ListItemText primary='Preps' />
                </ListItem>
                <ListItem
                  component={Link}
                  button
                  key='Main Ingredients'
                  to='/admin/ingredient'>
                  <ListItemIcon>
                    <Kitchen />
                  </ListItemIcon>
                  <ListItemText primary='Manage Ingredients' />
                </ListItem>
              </>
            )}

            <>
              <Divider />
              <RecipeList
                recipes={pinnedRecipes}
                getSecondaryText={getRecipeSecondaryText}
                onRecipeClick={onRecipeClick}
              />
            </>
          </List>
        </div>
      </Drawer>
      <Toolbar />
    </>
  )
}

export default withRouter(AppBarAndDrawer)
