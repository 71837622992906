import { IngredientLineWithoutMainIngredient } from '../../types'
import { PREP_NOT_COMMUNICATED } from './utils/prep'
import { QUANTITY_NOT_COMMUNICATED } from './utils/quantity'
import {
  addDe,
  PIECE_UNITY,
  pluralizeUnity,
  UNITY_NOT_COMMUNICATED,
} from './utils/unity'

/**
 * Return a readable ingredientLine broken down in chunks
 * @param ingredientLine
 * @category ingredientLine
 */
export const prebuildIngredientLine = (
  ingredientLine: IngredientLineWithoutMainIngredient
) => {
  const { quantity, unity, ingredient, prep } = ingredientLine

  const plural =
    quantity === QUANTITY_NOT_COMMUNICATED || parseInt(quantity) > 1

  let humanizedUnity = ''
  if (unity && unity !== PIECE_UNITY && unity !== UNITY_NOT_COMMUNICATED) {
    humanizedUnity += plural ? pluralizeUnity(unity) : unity
    humanizedUnity += ' '
    humanizedUnity += addDe(unity, ingredient)
  }

  return {
    quantity:
      quantity && quantity !== QUANTITY_NOT_COMMUNICATED ? quantity + ' ' : '',
    unity: humanizedUnity,
    ingredient: ingredient,
    prep: prep && prep !== PREP_NOT_COMMUNICATED ? ', ' + prep : '',
  }
}

/**
 * Return a readable ingredientLine
 * @param ingredientLine
 * @category ingredientLine
 */
export const buildIngredientLine = (
  ingredientLine: IngredientLineWithoutMainIngredient
): string => Object.values(prebuildIngredientLine(ingredientLine)).join('')
