import { useState } from 'react'
import {
  isSavedIngredient,
  MainIngredient,
  MonthlySeasonScore,
  UnsavedMainIngredient,
} from '../../../@aligre/types'

interface MainIngredientFormFields {
  _id?: string
  name: string
  recipe: string
  seasonalityStr: string
  aisle?: string
}

function toMainIngredientForm(
  i: MainIngredient | UnsavedMainIngredient
): MainIngredientFormFields {
  return {
    ...(isSavedIngredient(i) && { _id: i._id }),
    name: i.name || '',
    recipe: i.recipe || '',
    seasonalityStr: Array.isArray(i.seasonality) ? i.seasonality.join(',') : '',
    aisle: i.aisle,
  }
}

function isSeasonalityValid(str: string | undefined) {
  if (str) {
    const seasonalityTmp = str.split(',')
    if (
      seasonalityTmp.length === 12 &&
      seasonalityTmp.filter((k) => ['0', '1', '2'].includes(k)).length === 12
    )
      return true
    return false
  }
  return false
}

function toMainIngredientDatabase(
  i: MainIngredientFormFields
): MainIngredient | UnsavedMainIngredient {
  const name = i.name.trim()
  const seasonalityArr = i.seasonalityStr.split(',')
  const seasonality =
    seasonalityArr.length === 12
      ? (seasonalityArr.map((s) => +s) as MonthlySeasonScore)
      : undefined

  const recipe = !i.recipe ? undefined : i.recipe
  return {
    ...(i._id && { _id: i._id }),
    name,
    ...(seasonality && { seasonality }),
    ...(recipe && { recipe }),
    ...(i.aisle && { aisle: i.aisle }),
  }
}

function getSeasonality(str: string) {
  return str.split(',').map((s) => +s) as MonthlySeasonScore
}

const useMainIngredientForm = (
  initialState: MainIngredient | UnsavedMainIngredient,
  onSubmit: (mainIngredient: MainIngredient | UnsavedMainIngredient) => void
) => {
  const [fields, setFields] = useState(toMainIngredientForm(initialState))

  function handleFieldChange<K extends keyof MainIngredientFormFields>(
    fieldName: K,
    value: MainIngredientFormFields[K]
  ) {
    setFields((fields) => ({ ...fields, [fieldName]: value }))
  }

  function isFormValid() {
    if (typeof fields.name !== 'string' || fields.name.trim().length === 0)
      return false
    if (fields.seasonalityStr && !isSeasonalityValid(fields.seasonalityStr)) {
      return false
    }

    return true
  }

  async function handleSubmit() {
    const mainIngredient = toMainIngredientDatabase(fields)
    onSubmit(mainIngredient)
  }

  return {
    handleFieldChange,
    fields,
    handleSubmit,
    isFormValid,
    isSeasonalityValid,
    getSeasonality,
  }
}
export default useMainIngredientForm
