import React, { useReducer } from 'react'
import useWhyDidIUpdate from '../../hooks/useWhyDidIUpdate'
import userReducer, { initialState, UserAction, UserState } from './userReducer'

const UserContext = React.createContext<
  UserState & {
    userDispatch: React.Dispatch<UserAction>
  }
>({ ...initialState, userDispatch: () => {} })

interface UserProviderProps {
  children: any
}
function UserProvider(props: UserProviderProps) {
  console.count('[render] UserProvider')
  const [userState, userDispatch] = useReducer(userReducer, initialState)
  useWhyDidIUpdate('UserProvider', props)
  useWhyDidIUpdate('UserProvider Internal State', { userState, userDispatch })
  return (
    <UserContext.Provider value={{ ...userState, userDispatch }}>
      {props.children}
    </UserContext.Provider>
  )
}
export { UserContext, UserProvider }
