import React, { useState } from 'react'
import { SeasonScore } from '../../@aligre/types'
import useWhyDidIUpdate from '../../hooks/useWhyDidIUpdate'

const currentMonth = new Date().getMonth()

// @todo remove hard coded values for categories
const defaultSearchParams = {
  recipeName: '',
  setRecipeName: (name: string) => {},
  selectedMonth: currentMonth,
  selectedMainIngredients: [] as string[],
  setSelectedMainIngredients: (selected: string[]) => {},
  selectedCategories: [
    '5d7b4386ddd93907ef30f6a7',
    // '5d7b4386ddd93907ef30f6b0', // sides
    // '5d7b4386ddd93907ef30f6a8',// soupes
  ],
  selectedSources: [] as string[],
  minimumIngredientsInCommon: 1,
  minimumSeasonScore: SeasonScore.Ok,
  neverRatedOnly: true,
  missingPictureOnly: false,
  setSelectedCategories: (selected: string[]) => {},
  setSelectedSources: (selected: string[]) => {},
  setMinimumIngredientsInCommon: (selected: number) => {},
  setMinimumSeasonScore: (selected: SeasonScore) => {},
  setNeverRatedOnly: (selected: boolean) => {},
  setMissingPictureOnly: (selected: boolean) => {},
}

const SearchContext = React.createContext(defaultSearchParams)

interface SearchProviderProps {
  children: any
}
function SearchProvider(props: SearchProviderProps) {
  console.count('[render] SearchProvider')
  const [recipeName, setRecipeName] = useState('')
  const [selectedMainIngredients, setSelectedMainIngredients] = useState(
    defaultSearchParams.selectedMainIngredients
  )
  const [selectedCategories, setSelectedCategories] = useState(
    defaultSearchParams.selectedCategories
  )
  const [selectedSources, setSelectedSources] = useState(
    defaultSearchParams.selectedSources
  )
  const [minimumIngredientsInCommon, setMinimumIngredientsInCommon] = useState(
    defaultSearchParams.minimumIngredientsInCommon
  )
  const [minimumSeasonScore, setMinimumSeasonScore] = useState(
    defaultSearchParams.minimumSeasonScore
  )
  const [neverRatedOnly, setNeverRatedOnly] = useState(
    defaultSearchParams.neverRatedOnly
  )
  const [missingPictureOnly, setMissingPictureOnly] = useState(
    defaultSearchParams.missingPictureOnly
  )

  const [selectedMonth] = useState(defaultSearchParams.selectedMonth)

  useWhyDidIUpdate('SearchProvider', props)
  useWhyDidIUpdate('SearchProvider Internal State', {
    recipeName,
    setRecipeName,
    selectedMonth,
    selectedMainIngredients,
    setSelectedMainIngredients,
    selectedCategories,
    setSelectedCategories,
    selectedSources,
    setSelectedSources,
    minimumIngredientsInCommon,
    setMinimumIngredientsInCommon,
    minimumSeasonScore,
    setMinimumSeasonScore,
    neverRatedOnly,
    setNeverRatedOnly,
    missingPictureOnly,
    setMissingPictureOnly,
  })

  return (
    <SearchContext.Provider
      value={{
        recipeName,
        setRecipeName,
        selectedMonth,
        selectedMainIngredients,
        setSelectedMainIngredients,
        selectedCategories,
        setSelectedCategories,
        selectedSources,
        setSelectedSources,
        minimumIngredientsInCommon,
        setMinimumIngredientsInCommon,
        minimumSeasonScore,
        setMinimumSeasonScore,
        neverRatedOnly,
        setNeverRatedOnly,
        missingPictureOnly,
        setMissingPictureOnly,
      }}>
      {props.children}
    </SearchContext.Provider>
  )
}
export { SearchContext, SearchProvider }
