/**
 * @private
 */
export const QUANTITY_NOT_COMMUNICATED = 'N/C'
/**
 * @private
 */
export const acceptedNumbers = '0-9,./½¼¾'

/**
 * Is Quantity valid?
 * @param quantity
 * @category ingredientLine
 */
export const isQuantityValid = (quantity: string): boolean => {
  const regExp = new RegExp(`^[${acceptedNumbers}]+$`)
  return regExp.test(quantity) || quantity === QUANTITY_NOT_COMMUNICATED
}
