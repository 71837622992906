import { useContext, useEffect, useState } from 'react'
import useWhyDidIUpdate from '../../hooks/useWhyDidIUpdate'
import { fetchUser } from '../../services'
import { UserContext } from './UserProvider'
import { userActions } from './userReducer'

interface UserFetcherProps {
  children: any
}

export default function UserFetcher(props: UserFetcherProps) {
  console.count('[render] UserFetcher')
  const { userDispatch } = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  useWhyDidIUpdate('UserFetcher', props)
  useWhyDidIUpdate('UserFetcher Internal State', { loading, setLoading })
  useWhyDidIUpdate('UserFetcher context', useContext(UserContext))

  useEffect(() => {
    const fetchAndSetUser = async () => {
      try {
        const user = await fetchUser()
        userDispatch(userActions.reset(user))
      } catch (e) {
        console.error(e.message)
      } finally {
        setLoading(false)
      }
    }
    fetchAndSetUser()
  }, [userDispatch])

  if (loading) return null
  return props.children
}
