/**
 * @private
 */
export const PREP_NOT_COMMUNICATED = 'N/A'

/**
 * Is prep valid?
 * @param prep
 * @category ingredientLine
 */
export const isPrepValid = (prep: string): boolean => {
  return (
    (prep.length > 3 || prep === PREP_NOT_COMMUNICATED) &&
    prep.trim().length === prep.length
  )
}
