import Fab from '@material-ui/core/Fab'
import { makeStyles } from '@material-ui/core/styles'
import React, { useContext } from 'react'
import { isAdmin } from '../@aligre/lib/users'
import { UserContext } from '../state/user/UserProvider'

interface AbsoluteFabProps {
  onClick: () => void
  children: React.ReactNode
  adminOnly: boolean
}
const useStyles = makeStyles({
  fabAbsolute: {
    position: 'fixed',
    bottom: '1rem',
    right: '1rem',
    zIndex: 3000,
  },
})
export default function AbsoluteFab(props: AbsoluteFabProps) {
  const classes = useStyles()
  const userContext = useContext(UserContext)

  return props.adminOnly === false ||
    (props.adminOnly && isAdmin(userContext)) ? (
    <Fab
      color='secondary'
      aria-label='edit'
      onClick={props.onClick}
      className={classes.fabAbsolute}>
      {props.children}
    </Fab>
  ) : null
}
