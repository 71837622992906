import { useSnackbar } from 'notistack'
import React, { useCallback, useContext } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { findRecipeById } from '../../@aligre/lib/recipes'
import { isUnsavedRecipe, Recipe, UnsavedRecipe } from '../../@aligre/types'
import { createRecipe, deleteRecipe, updateRecipe } from '../../services/recipe'
import { RecipeContext } from '../../state/recipes/RecipeProvider'
import { recipeActions } from '../../state/recipes/recipeReducer'
import RecipeForm from './RecipeForm'

interface RecipeFormContainerProps
  extends RouteComponentProps<{
    _id?: string
  }> {}

const defaultRecipe: UnsavedRecipe = {
  name: '',
  servings: '',
  ingredients: [],
  directions: '',
  source: '',
  categories: [],
}

export default function RecipeFormContainer(props: RecipeFormContainerProps) {
  const { allRecipes, recipeDispatch } = useContext(RecipeContext)
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = useCallback(
    async (recipe: Recipe | UnsavedRecipe) => {
      try {
        if (isUnsavedRecipe(recipe)) {
          const { recipe: createdRecipe } = await createRecipe(recipe)
          recipeDispatch(recipeActions.addRecipe(createdRecipe))
          enqueueSnackbar('Recipe properly created')
          props.history.push(`/recipe/${createdRecipe._id}`)
        } else {
          const { recipe: updatedRecipe } = await updateRecipe(recipe)
          recipeDispatch(recipeActions.updateRecipe(updatedRecipe))
          enqueueSnackbar('Recipe properly updated')
          props.history.push(`/recipe/${updatedRecipe._id}`)
        }
      } catch (e) {
        enqueueSnackbar(e.message, { variant: 'error' })
      }
    },
    [enqueueSnackbar, recipeDispatch, props.history]
  )

  const onDeleteMemo = useCallback(
    async (recipe) => {
      try {
        const { message } = await deleteRecipe(recipe)
        recipeDispatch(recipeActions.deleteRecipe(recipe))
        enqueueSnackbar(message)
        props.history.push('/')
      } catch (e) {
        enqueueSnackbar(e.message, { variant: 'error' })
      }
    },
    [enqueueSnackbar, props.history, recipeDispatch]
  )

  let recipe: Recipe | UnsavedRecipe | undefined
  if (props.match.params._id) {
    recipe = findRecipeById(props.match.params._id, allRecipes)
    if (recipe === undefined) return <Redirect to='/404' />
  } else {
    recipe = {
      ...defaultRecipe,
    }
  }

  return (
    <RecipeForm
      recipe={recipe}
      onSubmit={onSubmit}
      onDelete={onDeleteMemo}
      key={props.match.params._id || ''}
    />
  )
}
