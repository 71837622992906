import { AisleDict } from '../../types'

/**
 * @internal
 */
const DEFAULT_AISLE = 'Miscellaneous'

/**
 * Get Default Aisle Name
 * @internal
 */
const getDefaultAisleName = (): string => DEFAULT_AISLE

/**
 * Get the aisle name
 * Default to the default aisle name if none found
 * @returns
 * @category aisle
 */
export const getAisleName = (
  aisleId: string | undefined,
  aisles: AisleDict
): string => aisles[aisleId || ''] || getDefaultAisleName()

/**
 * Get the default aisle Id
 * @returns The aisle Id or undefined if not found
 * @category aisle
 */
export const getDefaultAisleId = (aisles: AisleDict): string | undefined =>
  Object.keys(aisles).find((k) => {
    return aisles[k] === getDefaultAisleName()
  })
