import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import List from '@material-ui/core/List'
import TextField from '@material-ui/core/TextField'
import Add from '@material-ui/icons/Add'
import Clear from '@material-ui/icons/Clear'
import Delete from '@material-ui/icons/Delete'
import React, { useCallback, useMemo, useState } from 'react'
import {
  AisleDict,
  MainIngredient,
  MainIngredientDict,
} from '../../../@aligre/types'
import MainIngredientListByAisle from '../../../components/MainIngredientListByAisle'
import MainIngredientListItem from '../../../components/MainIngredientListItem'
import useWhyDidIUpdate from '../../../hooks/useWhyDidIUpdate'
import deburrAndLowerCase from '../../../lib/deburrAndLowerCase'

function getSuggestions(
  value: string,
  allValues: MainIngredient[],
  returnAll: boolean = false
) {
  const inputValue = deburrAndLowerCase(value)
  const inputLength = inputValue.length
  let count = 0

  return inputLength === 0
    ? returnAll
      ? allValues
      : []
    : allValues.filter(({ name }) => {
        const keep = count < 5 && deburrAndLowerCase(name).includes(inputValue)

        if (keep) {
          count += 1
        }

        return keep
      })
}

interface PantryListProps {
  aisles: AisleDict
  mainIngredients: MainIngredientDict
  pantryIngredients: MainIngredient[]
  onAdd: (ingredient: MainIngredient) => void
  onRemove: (ingredient: MainIngredient) => void
  onIngredientClick: (ingredient: MainIngredient) => void
}

export default function PantryList(props: PantryListProps) {
  const [value, setValue] = useState('')
  const { onAdd, mainIngredients, pantryIngredients } = props

  const mainIngredientsNotInPantry = useMemo(() => {
    const pantryIngredientIds = pantryIngredients.map(({ _id }) => _id)
    return Object.values(mainIngredients).filter(
      ({ _id }) => !pantryIngredientIds.includes(_id)
    )
  }, [mainIngredients, pantryIngredients])

  const notInPantryMatches = getSuggestions(value, mainIngredientsNotInPantry)
  const inPantryMatches = getSuggestions(value, pantryIngredients, true)

  const onAddMemo = useCallback(
    (ingredient) => {
      onAdd(ingredient)
      setValue('')
    },
    [onAdd]
  )

  const onClear = useCallback(() => {
    setValue('')
  }, [])

  useWhyDidIUpdate('PantryList', props)
  return (
    <>
      <TextField
        fullWidth
        value={value}
        label='Search for a Main Ingredient'
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value)
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton aria-label='Clear search' onClick={onClear}>
                <Clear />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <List>
        {notInPantryMatches.map((ingredient) => (
          <MainIngredientListItem
            key={ingredient._id}
            ingredient={ingredient}
            onIngredientClick={onAddMemo}
            onIconClick={onAddMemo}
            icon={<Add />}
          />
        ))}
      </List>

      <MainIngredientListByAisle
        mainIngredients={inPantryMatches}
        onIngredientClick={props.onIngredientClick}
        onIconClick={props.onRemove}
        icon={<Delete />}
      />
    </>
  )
}
