import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import useWhyDidIUpdate from '../../hooks/useWhyDidIUpdate'
import { fetchRecipes } from '../../services'
import { RecipeContext } from './RecipeProvider'
import { recipeActions } from './recipeReducer'

interface RecipeFetcherProps {
  children: any
}

export default function RecipeFetcher(props: RecipeFetcherProps) {
  console.count('RecipeFetcher')
  const { enqueueSnackbar } = useSnackbar()
  const recipeContext = useContext(RecipeContext)
  const { recipeDispatch } = recipeContext
  const [loading, setLoading] = useState(true)
  useWhyDidIUpdate('RecipeFetcher', props)
  useWhyDidIUpdate('RecipeFetcher Context', recipeContext)
  useWhyDidIUpdate('RecipeFetcher Internal State', { loading, setLoading })

  useEffect(() => {
    const fetchAndSetRecipes = async () => {
      try {
        const results = await fetchRecipes()
        recipeDispatch(recipeActions.reset(results))
      } catch (e) {
        enqueueSnackbar(e.message, { variant: 'error' })
      } finally {
        setLoading(false)
      }
    }
    fetchAndSetRecipes()
  }, [enqueueSnackbar, recipeDispatch])

  if (loading) return null
  return props.children
}
