import { useSnackbar } from 'notistack'
import React, { useCallback, useContext } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { findMainIngredientById } from '../../@aligre/lib/mainIngredients'
import {
  isSavedIngredient,
  MainIngredient,
  UnsavedMainIngredient,
} from '../../@aligre/types'
import {
  createMainIngredient,
  deleteMainIngredient,
  updateMainIngredient,
} from '../../services/mainIngredient'
import { RecipeContext } from '../../state/recipes/RecipeProvider'
import { recipeActions } from '../../state/recipes/recipeReducer'
import MainIngredientForm from './MainIngredientForm'

interface MainIngredientFormContainerProps
  extends RouteComponentProps<{
    _id: string
  }> {}

export default function MainIngredientFormContainer(
  props: MainIngredientFormContainerProps
) {
  const { allMainIngredients, recipeDispatch } = useContext(RecipeContext)
  const { enqueueSnackbar } = useSnackbar()

  const onSubmitMemo = useCallback(
    async (mainIngredient: MainIngredient | UnsavedMainIngredient) => {
      try {
        if (isSavedIngredient(mainIngredient)) {
          const {
            mainIngredient: updatedIngredient,
          } = await updateMainIngredient(mainIngredient)
          recipeDispatch(recipeActions.updateMainIngredient(updatedIngredient))
          enqueueSnackbar('Main Ingredient properly updated')
          props.history.push(`/ingredient/${updatedIngredient._id}`)
        } else {
          const {
            mainIngredient: newMainIngredient,
          } = await createMainIngredient(mainIngredient)
          recipeDispatch(recipeActions.addMainIngredient(newMainIngredient))
          enqueueSnackbar('Main Ingredient properly updated')
          props.history.push(`/ingredient/${newMainIngredient._id}`)
        }
      } catch (e) {
        enqueueSnackbar(e.message, { variant: 'error' })
      }
    },
    [enqueueSnackbar, recipeDispatch, props.history]
  )

  const onDeleteMemo = useCallback(
    async (mainIngredient) => {
      try {
        const { message } = await deleteMainIngredient(mainIngredient)
        recipeDispatch(recipeActions.deleteMainIngredient(mainIngredient))
        enqueueSnackbar(message)
        props.history.push('admin/ingredients')
      } catch (e) {
        enqueueSnackbar(e.message, { variant: 'error' })
      }
    },
    [enqueueSnackbar, props.history, recipeDispatch]
  )

  const onViewMemo = useCallback(
    (mainIngredient) => {
      props.history.push(`/ingredient/${mainIngredient._id}`)
    },
    [props.history]
  )

  let ingredient: MainIngredient | UnsavedMainIngredient | undefined
  if (props.match.params._id) {
    ingredient = findMainIngredientById(
      props.match.params._id,
      allMainIngredients
    )
    if (ingredient === undefined) return <Redirect to='/404' />
  } else {
    ingredient = {
      name: '',
    }
  }

  return (
    <MainIngredientForm
      mainIngredient={ingredient}
      onSubmit={onSubmitMemo}
      onView={onViewMemo}
      onDelete={onDeleteMemo}
    />
  )
}
