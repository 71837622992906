import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, { useCallback, useContext, useState } from 'react'
import ReactDiffViewer from 'react-diff-viewer'
import {
  isSeparatorLine,
  isUnsavedRecipe,
  Recipe,
  UnsavedRecipe,
} from '../../@aligre/types'
import CategoryFormControlSelect from '../../components/CategoryFormControlSelect'
import DeleteButton from '../../components/DeleteButton'
import SeasonTable from '../../components/SeasonTable'
import { createSelectOptions } from '../../lib/selectOptions'
import { RecipeContext } from '../../state/recipes/RecipeProvider'
import PartialIngredientFormField from './components/PartialIngredientFormField'
import useRecipeForm from './hooks/useRecipeForm'
interface RecipeFormProps {
  recipe: Recipe | UnsavedRecipe
  onSubmit: (recipe: Recipe | UnsavedRecipe) => void
  onDelete: (recipe: Recipe) => void
}

const useStyles = makeStyles((theme) => ({
  margin: {
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1, 2, 0, 0),
  },
}))

export default function RecipeForm(props: RecipeFormProps) {
  const classes = useStyles()
  const { recipe: initialRecipe, onDelete } = props
  const { allRecipes, allMainIngredients, allSources } = useContext(
    RecipeContext
  )
  const [showDiff, setShowDiff] = useState(false)
  const {
    fields,
    handleFieldChange,
    ingredients,
    seasonScore,
    handleSubmit,
    setIngredientsFromIngredientLines,
    handleIngredientLineChange,
    handleMainIngredientChange,
    handleRemoveLine,
    formIsValid,
    diff,
  } = useRecipeForm(
    props.recipe,
    allRecipes,
    allMainIngredients,
    props.onSubmit
  )

  const [newIngredientLines, setIngredientLines] = useState<string>('')

  const onDeleteMemo = useCallback(() => {
    !isUnsavedRecipe(initialRecipe) && onDelete(initialRecipe)
  }, [onDelete, initialRecipe])

  const { oldCode, newCode } = showDiff ? diff() : { oldCode: '', newCode: '' }
  return (
    <Grid container>
      <Grid item xs={12} className={classes.margin}>
        <TextField
          fullWidth
          label='Recipe Name'
          value={fields.name}
          onChange={(event) => {
            handleFieldChange('name', event.currentTarget.value)
          }}
          required
        />
      </Grid>
      <Grid item xs={12} className={classes.margin}>
        {ingredients.map((ingredient, index) => {
          if (isSeparatorLine(ingredient)) {
            return <div>{ingredient.line}</div>
          }
          return (
            <PartialIngredientFormField
              key={index}
              index={index}
              ingredient={ingredient}
              guesses={true}
              onChange={handleIngredientLineChange}
              onMainIngredientChange={handleMainIngredientChange}
              onRemove={handleRemoveLine}
            />
          )
        })}
      </Grid>
      <Grid item xs={12} className={classes.margin}>
        <TextField
          fullWidth
          multiline={!ingredients.length}
          rows='3'
          label='Paste ingredients here...'
          value={newIngredientLines}
          onChange={(event) => {
            var emptyLinesTrimmed = (event.currentTarget.value || '').replace(
              /^\s*[\r\n]/gm,
              ''
            )
            setIngredientLines(emptyLinesTrimmed)
          }}
        />
        <Button
          disabled={newIngredientLines.trim() === ''}
          onClick={() => {
            const newIngredients = newIngredientLines.trim().split('\n')
            setIngredientsFromIngredientLines(newIngredients)
            setIngredientLines('')
          }}>
          Parse Ingredients
        </Button>
      </Grid>

      <Grid item xs={12} className={classes.margin}>
        <SeasonTable seasonality={seasonScore} />
      </Grid>

      <Grid item xs={12} className={classes.margin}>
        <TextField
          fullWidth
          label='Directions'
          multiline
          rows='2'
          rowsMax='10'
          value={fields.directions}
          onChange={(event) => {
            handleFieldChange('directions', event.currentTarget.value)
          }}
        />
      </Grid>

      <Grid item xs={12} className={classes.margin}>
        <CategoryFormControlSelect
          selected={fields.categories}
          onChange={(selected) => handleFieldChange('categories', selected)}
        />
      </Grid>

      <Grid item xs={12} className={classes.margin}>
        <TextField
          fullWidth
          label='Servings'
          helperText='Number as String'
          value={fields.servings}
          onChange={(event) => {
            handleFieldChange('servings', event.currentTarget.value)
          }}
        />
      </Grid>

      <Grid item xs={12} className={classes.margin}>
        <FormControl fullWidth>
          <InputLabel htmlFor='select-source'>Source</InputLabel>
          <Select
            inputProps={{
              id: 'select-source',
            }}
            value={fields.source}
            onChange={(event) => {
              handleFieldChange('source', event.target.value as string)
            }}>
            {createSelectOptions(allSources).map(({ value, label }) => {
              return (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} className={classes.margin}>
        <TextField
          fullWidth
          label='Source Url'
          value={fields.sourceUrl || ''}
          onChange={(event) => {
            handleFieldChange('sourceUrl', event.currentTarget.value)
          }}
        />
      </Grid>

      <Grid item xs={12} className={classes.margin}>
        <TextField
          fullWidth
          type='number'
          label='Prep Time'
          value={fields.prepTime || ''}
          onChange={(event) => {
            const prepTime = parseInt(event.currentTarget.value)
            handleFieldChange(
              'prepTime',
              isNaN(prepTime) ? undefined : prepTime
            )
          }}
        />
      </Grid>

      <Grid item xs={12} className={classes.margin}>
        <TextField
          fullWidth
          type='number'
          label='Cook Time'
          value={fields.cookTime || ''}
          onChange={(event) => {
            const cookTime = parseInt(event.currentTarget.value)
            handleFieldChange(
              'cookTime',
              isNaN(cookTime) ? undefined : cookTime
            )
          }}
        />
      </Grid>

      <Grid item xs={12}>
        {!isUnsavedRecipe(props.recipe) && (
          <DeleteButton onAgree={onDeleteMemo} className={classes.button} />
        )}
        <Button
          variant='contained'
          color='default'
          onClick={() => setShowDiff((v) => !v)}
          className={classes.button}>
          Preview
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={handleSubmit}
          className={classes.button}
          disabled={!formIsValid}>
          Submit
        </Button>
      </Grid>
      <Grid item xs={12} className={classes.margin}>
        {showDiff && (
          <ReactDiffViewer
            oldValue={JSON.stringify(oldCode, null, 2)}
            newValue={JSON.stringify(newCode, null, 2)}
            splitView={true}
          />
        )}
      </Grid>
    </Grid>
  )
}
