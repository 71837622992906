import {
  Aisle,
  AisleDict,
  Category,
  CategoryDict,
  MainIngredient,
  MainIngredientDict,
  Recipe,
  Source,
  SourceDict,
} from '../../@aligre/types'

// --- TYPES

enum ACTION_TYPES {
  RESET_RECIPES = 'RESET_RECIPES',
  ADD_RECIPE = 'ADD_RECIPE',
  UPDATE_RECIPE = 'UPDATE_RECIPE',
  DELETE_RECIPE = 'DELETE_RECIPE',
  UPDATE_MAIN_INGREDIENT = 'UPDATE_MAIN_INGREDIENT',
  DELETE_MAIN_INGREDIENT = 'DELETE_MAIN_INGREDIENT',
  ADD_MAIN_INGREDIENT = 'ADD_MAIN_INGREDIENT',
}

export const initialState = {
  allRecipes: [] as Recipe[],
  allSources: {} as SourceDict,
  allCategories: {} as CategoryDict,
  allMainIngredients: {} as MainIngredientDict,
  aisles: {} as AisleDict,
}

export type RecipeState = typeof initialState

// --- ACTIONS

function resetRecipes(payload: {
  recipes: Recipe[]
  mainIngredients: MainIngredient[]
  categories: Category[]
  sources: Source[]
  aisles: Aisle[]
}) {
  return {
    type: ACTION_TYPES.RESET_RECIPES as ACTION_TYPES.RESET_RECIPES,
    ...payload,
  }
}

function addRecipe(recipe: Recipe) {
  return {
    type: ACTION_TYPES.ADD_RECIPE as ACTION_TYPES.ADD_RECIPE,
    recipe,
  }
}

function updateRecipe(recipe: Recipe) {
  return {
    type: ACTION_TYPES.UPDATE_RECIPE as ACTION_TYPES.UPDATE_RECIPE,
    recipe,
  }
}

function updateMainIngredient(mainIngredient: MainIngredient) {
  return {
    type: ACTION_TYPES.UPDATE_MAIN_INGREDIENT as ACTION_TYPES.UPDATE_MAIN_INGREDIENT,
    mainIngredient,
  }
}

function deleteRecipe(recipe: Recipe) {
  return {
    type: ACTION_TYPES.DELETE_RECIPE as ACTION_TYPES.DELETE_RECIPE,
    recipe,
  }
}

function deleteMainIngredient(mainIngredient: MainIngredient) {
  return {
    type: ACTION_TYPES.DELETE_MAIN_INGREDIENT as ACTION_TYPES.DELETE_MAIN_INGREDIENT,
    mainIngredient,
  }
}

function addMainIngredient(mainIngredient: MainIngredient) {
  return {
    type: ACTION_TYPES.ADD_MAIN_INGREDIENT as ACTION_TYPES.ADD_MAIN_INGREDIENT,
    mainIngredient,
  }
}

export type RecipeAction =
  | ReturnType<typeof resetRecipes>
  | ReturnType<typeof addRecipe>
  | ReturnType<typeof updateRecipe>
  | ReturnType<typeof deleteRecipe>
  | ReturnType<typeof addMainIngredient>
  | ReturnType<typeof updateMainIngredient>
  | ReturnType<typeof deleteMainIngredient>

export const recipeActions = {
  reset: resetRecipes,
  addRecipe,
  updateRecipe,
  deleteRecipe,
  addMainIngredient,
  updateMainIngredient,
  deleteMainIngredient,
}

// --- REDUCER

export default function reducer(
  state = initialState,
  action: RecipeAction
): RecipeState {
  switch (action.type) {
    case ACTION_TYPES.RESET_RECIPES:
      return {
        allRecipes: action.recipes,
        allSources: action.sources.reduce((acc, entry) => {
          return { ...acc, [entry._id]: entry.name }
        }, {} as SourceDict),

        allCategories: action.categories.reduce((acc, entry) => {
          return { ...acc, [entry._id]: entry.name }
        }, {} as CategoryDict),
        allMainIngredients: action.mainIngredients.reduce(
          (acc, mainIngredientEntry) => {
            return { ...acc, [mainIngredientEntry._id]: mainIngredientEntry }
          },
          {} as MainIngredientDict
        ),
        aisles: action.aisles.reduce((acc, entry) => {
          acc[entry._id] = entry.name
          return acc
        }, {} as AisleDict),
      }

    case ACTION_TYPES.ADD_RECIPE:
      const hasAlreadyRecipe = state.allRecipes.some(
        (r) => r._id === action.recipe._id
      )
      return hasAlreadyRecipe
        ? state
        : { ...state, allRecipes: [...state.allRecipes, action.recipe] }

    case ACTION_TYPES.UPDATE_RECIPE:
      const newRecipes = state.allRecipes.map((recipe) => {
        if (recipe._id === action.recipe._id) {
          return action.recipe
        }
        return recipe
      })
      return { ...state, allRecipes: newRecipes }

    case ACTION_TYPES.DELETE_RECIPE:
      return {
        ...state,
        allRecipes: state.allRecipes.filter(
          (recipe) => recipe._id !== action.recipe._id
        ),
      }

    case ACTION_TYPES.UPDATE_MAIN_INGREDIENT:
      if (state.allMainIngredients[action.mainIngredient._id]) {
        return {
          ...state,
          allMainIngredients: {
            ...state.allMainIngredients,
            [action.mainIngredient._id]: action.mainIngredient,
          },
        }
      }
      return state

    case ACTION_TYPES.ADD_MAIN_INGREDIENT:
      return {
        ...state,
        allMainIngredients: {
          ...state.allMainIngredients,
          [action.mainIngredient._id]: action.mainIngredient,
        },
      }

    case ACTION_TYPES.DELETE_MAIN_INGREDIENT:
      if (state.allMainIngredients[action.mainIngredient._id]) {
        const {
          [action.mainIngredient._id]: value,
          ...remainingMainIngredients
        } = state.allMainIngredients

        return {
          ...state,
          allMainIngredients: remainingMainIngredients,
        }
      }
      return state

    default:
      return state
  }
}
