import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Slider from '@material-ui/core/Slider'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FiberNew from '@material-ui/icons/FiberNew'
import BrokenImage from '@material-ui/icons/BrokenImage'
import BrokenImageOutlined from '@material-ui/icons/BrokenImageOutlined'
import FiberNewOutlined from '@material-ui/icons/FiberNewOutlined'
import SentimentSatisfied from '@material-ui/icons/SentimentSatisfied'
import SentimentSatisfiedAlt from '@material-ui/icons/SentimentSatisfiedAlt'
import SentimentVeryDissatisfied from '@material-ui/icons/SentimentVeryDissatisfied'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import React, { useCallback, useContext, useMemo } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { findMainIngredientsById } from '../../@aligre/lib/mainIngredients'
import { getRecipeSource } from '../../@aligre/lib/recipes'
import { filterUndefined, Recipe, SeasonScore } from '../../@aligre/types'
import CategoryFormControlSelect from '../../components/CategoryFormControlSelect'
import RecipeList from '../../components/RecipeList'
import SourceFormControlSelect from '../../components/SourceFormControlSelect'
import useWhyDidIUpdate from '../../hooks/useWhyDidIUpdate'
import { RecipeContext } from '../../state/recipes/RecipeProvider'
import { SearchContext } from '../../state/search/SearchProvider'
import { UserContext } from '../../state/user/UserProvider'
import MainIngredientSearch from './components/MainIngredientSearch'
import getMatchingRecipes from './lib/searchRecipes'

interface RecipeSearchProps extends RouteComponentProps {}

const useStyles = makeStyles((theme) => ({
  margin: {
    marginBottom: theme.spacing(2),
  },
  slider: {
    paddingTop: theme.spacing(3),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}))

export default function RecipeSearchPage(props: RecipeSearchProps) {
  const classes = useStyles()
  console.count('RecipeSearchPage')
  const userContext = useContext(UserContext)
  const recipeContext = useContext(RecipeContext)
  const { allRecipes, allMainIngredients, allSources } = recipeContext
  useWhyDidIUpdate('RecipeSearchPage', props)
  useWhyDidIUpdate('RecipeSearchPage recipeContext', recipeContext)

  const { setRecipeName, ...searchProps } = useContext(SearchContext)

  const matches = useMemo(
    () =>
      getMatchingRecipes(
        allRecipes,
        searchProps,
        userContext.pantry,
        userContext.ratings
      ),
    [searchProps, allRecipes, userContext.pantry, userContext.ratings]
  )

  const pantryMainIngredients = useMemo(
    () =>
      filterUndefined(
        findMainIngredientsById(
          Object.keys(userContext.pantry),
          allMainIngredients
        )
      ),
    [allMainIngredients, userContext.pantry]
  )

  const onRecipeClick = useCallback(
    (recipe: Recipe) => {
      props.history.push(`/recipe/${recipe._id}`)
    },
    [props.history]
  )

  const getRecipeSecondaryText = useCallback(
    (recipe: Recipe) => getRecipeSource(recipe, allSources),
    [allSources]
  )

  const setRecipeNameMemo = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRecipeName(event.currentTarget.value)
    },
    [setRecipeName]
  )

  useWhyDidIUpdate('RecipeSearchPage onRecipeClick', { onRecipeClick })
  useWhyDidIUpdate('RecipeSearchPage searchParamsContext', searchProps)

  return (
    <Grid container>
      <Grid item xs={12} className={classes.margin}>
        <TextField
          label='Name'
          fullWidth
          value={searchProps.recipeName}
          onChange={setRecipeNameMemo}
        />
      </Grid>

      <Grid item xs={12} className={classes.margin}>
        <MainIngredientSearch
          month={searchProps.selectedMonth}
          mainIngredients={allMainIngredients}
          pantryIngredients={pantryMainIngredients}
          selectedMainIngredients={searchProps.selectedMainIngredients}
          onSelect={(selected) => {
            searchProps.setSelectedMainIngredients(selected)
          }}
        />
      </Grid>

      <Grid item xs={12} className={classes.margin}>
        <CategoryFormControlSelect
          selected={searchProps.selectedCategories}
          onChange={(selected) => searchProps.setSelectedCategories(selected)}
        />
      </Grid>

      <Grid item xs={4}>
        <ToggleButtonGroup
          value={`${searchProps.minimumSeasonScore}`}
          exclusive
          onChange={(event: React.MouseEvent, newType: SeasonScore | null) => {
            searchProps.setMinimumSeasonScore(newType || 0)
          }}
          size='small'>
          <ToggleButton value={`${SeasonScore.NoGo}`}>
            <SentimentVeryDissatisfied />
          </ToggleButton>
          <ToggleButton value={`${SeasonScore.Ok}`}>
            <SentimentSatisfied />
          </ToggleButton>
          <ToggleButton value={`${SeasonScore.Perfect}`}>
            <SentimentSatisfiedAlt />
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      <Grid item xs={2}>
        <Checkbox
          icon={<FiberNewOutlined />}
          checkedIcon={<FiberNew />}
          checked={searchProps.neverRatedOnly}
          onChange={(event) =>
            searchProps.setNeverRatedOnly(event.target.checked)
          }
        />
      </Grid>

      <Grid item xs={2}>
        <Checkbox
          icon={<BrokenImage />}
          checkedIcon={<BrokenImageOutlined />}
          checked={searchProps.missingPictureOnly}
          onChange={(event) =>
            searchProps.setMissingPictureOnly(event.target.checked)
          }
        />
      </Grid>

      <Grid item xs={4}>
        <Slider
          className={classes.slider}
          disabled={searchProps.selectedMainIngredients.length < 2}
          value={searchProps.minimumIngredientsInCommon}
          valueLabelDisplay='auto'
          onChange={(event, value) => {
            searchProps.setMinimumIngredientsInCommon(value as number)
          }}
          step={1}
          marks
          min={1}
          max={searchProps.selectedMainIngredients.length}
        />
      </Grid>

      <Grid item xs={12} className={classes.margin}>
        <SourceFormControlSelect
          multiple
          selected={searchProps.selectedSources}
          onChange={(selected) =>
            searchProps.setSelectedSources(selected as string[])
          }
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant='caption' display='block' gutterBottom>
          {matches.length} results
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RecipeList
          recipes={matches}
          pinned={userContext.pinned}
          getSecondaryText={getRecipeSecondaryText}
          onRecipeClick={onRecipeClick}
        />
      </Grid>
    </Grid>
  )
}
