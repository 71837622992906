import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import MainIngredientFormContainer from './pages/AdminMainIngredients/MainIngredientFormContainer'
import AdminMainIngredientsPage from './pages/AdminMainIngredients/MainIngredientsPageContainer'
import RecipeFormContainer from './pages/AdminRecipes/RecipeFormContainer'
import AppBarAndDrawer from './pages/AppBarAndDrawer'
import LoginPage from './pages/Auth/LoginPage'
import NotFoundPage from './pages/Errors/NotFoundPage'
import GroceryListContainer from './pages/GroceryList/GroceryListContainer'
import IngredientPageContainer from './pages/Ingredient/IngredientPageContainer'
import PantryPageContainer from './pages/Pantry/PantryPageContainer'
import RecipePageContainer from './pages/Recipe/RecipePageContainer'
import RecipeSearchPage from './pages/RecipeSearch/RecipeSearchPage'
import SeasonalProduceContainer from './pages/SeasonalProduce/SeasonalProduceContainer'
import StatsPage from './pages/Stats/StatsPage'
import RecipeFetcher from './state/recipes/RecipeFetcher'
import { RecipeProvider } from './state/recipes/RecipeProvider'
import { SearchProvider } from './state/search/SearchProvider'
import UserFetcher from './state/user/UserFetcher'
import { UserProvider } from './state/user/UserProvider'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#7cb342',
    },
    secondary: {
      main: '#ffb74d',
    },
  },
})

export default function App() {
  console.count('[render] App')
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <UserProvider>
          <RecipeProvider>
            <UserFetcher>
              <CssBaseline />
              <AppBarAndDrawer />
              <Container maxWidth={false}>
                <SearchProvider>
                  <SnackbarProvider>
                    <Box my={1}>
                      <Switch>
                        <Route exact path='/login' component={LoginPage} />
                        <RecipeFetcher>
                          <Switch>
                            <Route
                              exact
                              path='/'
                              component={RecipeSearchPage}
                            />
                            <Route
                              path='/pantry'
                              component={PantryPageContainer}
                            />
                            <Route
                              path='/seasonal'
                              component={SeasonalProduceContainer}
                            />
                            <Route
                              exact
                              path='/grocery'
                              component={GroceryListContainer}
                            />
                            <Route
                              path='/recipe/:_id'
                              component={RecipePageContainer}
                            />
                            <Route
                              path='/ingredient/:_id'
                              component={IngredientPageContainer}
                            />
                            <Route
                              exact
                              path='/admin/recipe/create'
                              component={RecipeFormContainer}
                            />
                            <Route
                              path='/admin/recipe/edit/:_id'
                              component={RecipeFormContainer}
                            />
                            <Route
                              path='/admin/ingredient/create'
                              component={MainIngredientFormContainer}
                            />
                            <Route
                              path='/admin/ingredient/edit/:_id'
                              component={MainIngredientFormContainer}
                            />
                            <Route
                              path='/admin/ingredient'
                              component={AdminMainIngredientsPage}
                            />
                            <Route
                              exact
                              path='/admin/preps'
                              component={StatsPage}
                            />
                          </Switch>
                        </RecipeFetcher>

                        <Switch>
                          <Route path='/not-found' component={NotFoundPage} />
                          <Route component={NotFoundPage} />
                        </Switch>
                      </Switch>
                    </Box>
                  </SnackbarProvider>
                </SearchProvider>
              </Container>
            </UserFetcher>
          </RecipeProvider>
        </UserProvider>
      </Router>
    </MuiThemeProvider>
  )
}
