import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import List from '@material-ui/core/List'
import TextField from '@material-ui/core/TextField'
import Add from '@material-ui/icons/Add'
import Clear from '@material-ui/icons/Clear'
import Kitchen from '@material-ui/icons/Kitchen'
import React, { useCallback, useMemo, useState } from 'react'
import { MainIngredient, MainIngredientDict } from '../../../@aligre/types'
import MainIngredientListItem from '../../../components/MainIngredientListItem'
import useWhyDidIUpdate from '../../../hooks/useWhyDidIUpdate'
import deburrAndLowerCase from '../../../lib/deburrAndLowerCase'

function getSuggestions(
  value: string,
  allValues: MainIngredient[],
  returnAll: boolean = false,
  max: number = 10
) {
  const inputValue = deburrAndLowerCase(value)
  const inputLength = inputValue.length
  let count = 0

  return inputLength === 0
    ? returnAll
      ? allValues
      : []
    : allValues.filter(({ name }) => {
        const keep =
          count < max && deburrAndLowerCase(name).includes(inputValue)

        if (keep) {
          count += 1
        }

        return keep
      })
}

interface MainIngredientSearchProps {
  mainIngredients: MainIngredientDict
  pantryIngredients: MainIngredient[]
  selectedMainIngredients: string[]
  onSelect: (mainIngredients: string[]) => void
  month: number
}

export default function MainIngredientSearch(props: MainIngredientSearchProps) {
  const [value, setValue] = useState('')
  const [selected, setSelected] = useState(props.selectedMainIngredients)
  const { onSelect, mainIngredients, pantryIngredients } = props

  const nonSelectedIngredients = useMemo(() => {
    return Object.values(mainIngredients).filter(
      ({ _id }) => !selected.includes(_id)
    )
  }, [mainIngredients, selected])

  const pantryIngredientIds = useMemo(
    () => pantryIngredients.map(({ _id }) => _id),
    [pantryIngredients]
  )

  const matches = useMemo(() => getSuggestions(value, nonSelectedIngredients), [
    nonSelectedIngredients,
    value,
  ])

  const onAddMemo = useCallback(
    (ingredient: MainIngredient) => {
      setSelected((selected) => {
        const newSelected = [...selected, ingredient._id]
        onSelect(newSelected)
        return newSelected
      })
      setValue('')
    },
    [onSelect]
  )

  const onDeleteMemo = useCallback(
    (ingredient: MainIngredient) => {
      setSelected((selected) => {
        const newSelected = selected.filter((i) => i !== ingredient._id)
        onSelect(newSelected)
        return newSelected
      })
    },
    [onSelect]
  )

  const onClear = useCallback(() => {
    setValue('')
  }, [])

  useWhyDidIUpdate('MainIngredientSearch', props)
  return (
    <>
      <TextField
        fullWidth
        value={value}
        label='Search for a Main Ingredient'
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value)
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton aria-label='Clear search' onClick={onClear}>
                <Clear />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {matches.length > 0 && (
        <List>
          {matches.map((ingredient) => (
            <MainIngredientListItem
              key={ingredient._id}
              month={props.month}
              ingredient={ingredient}
              onIngredientClick={onAddMemo}
              onIconClick={onAddMemo}
              icon={<Add />}
            />
          ))}
        </List>
      )}

      <div>
        {selected.map((ingredientId) => (
          <Chip
            size='small'
            icon={
              pantryIngredientIds.includes(ingredientId) ? (
                <Kitchen />
              ) : undefined
            }
            key={ingredientId}
            label={mainIngredients[ingredientId].name}
            onDelete={() => onDeleteMemo(mainIngredients[ingredientId])}
          />
        ))}
      </div>
    </>
  )
}
