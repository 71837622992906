import { MainIngredient, UnsavedMainIngredient } from '../@aligre/types'
import {
  CreateMainIngredientRequestBody,
  DeleteResponse,
  MainIngredientRequestBody,
  MainIngredientResponse,
} from '../@aligre/types/api'
import apiFetch from './apiFetch'

export async function createMainIngredient(
  mainIngredient: UnsavedMainIngredient
) {
  return await apiFetch<
    CreateMainIngredientRequestBody,
    MainIngredientResponse
  >('ingredients/', 'POST', { mainIngredient })
}

export async function deleteMainIngredient(mainIngredient: MainIngredient) {
  return await apiFetch<void, DeleteResponse>(
    `ingredients/${mainIngredient._id}`,
    'DELETE'
  )
}

export async function updateMainIngredient(mainIngredient: MainIngredient) {
  return await apiFetch<MainIngredientRequestBody, MainIngredientResponse>(
    `ingredients/${mainIngredient._id}`,
    'POST',
    {
      mainIngredient,
    }
  )
}
