import Edit from '@material-ui/icons/Edit'
import { useSnackbar } from 'notistack'
import React, { useCallback, useContext } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import {
  findRecipeById,
  getRecipeRating,
  isRecipeInGroceryList,
  isRecipePinned,
} from '../../@aligre/lib/recipes'
import AbsoluteFab from '../../components/AbsoluteFab'
import {
  addRecipeToGroceryList,
  deleteRecipeFromGroceryList,
} from '../../services/grocery'
import { pinRecipe, unpinRecipe } from '../../services/pin'
import { deleteRating, updateRating } from '../../services/rating'
import { RecipeContext } from '../../state/recipes/RecipeProvider'
import { UserContext } from '../../state/user/UserProvider'
import { userActions } from '../../state/user/userReducer'
import RecipePage from './RecipePage'

interface RecipePageContainerProps
  extends RouteComponentProps<{
    _id: string
  }> {}

export default function RecipePageContainer(props: RecipePageContainerProps) {
  const { _id } = props.match.params
  const { allRecipes } = useContext(RecipeContext)
  const userContext = useContext(UserContext)
  const recipe = findRecipeById(_id, allRecipes)
  const { enqueueSnackbar } = useSnackbar()

  const onParentRecipeClickMemo = useCallback(
    (recipe) => {
      props.history.push(`/recipe/${recipe._id}`)
    },
    [props.history]
  )

  const onAbsoluteFabClickMemo = useCallback(() => {
    props.history.push(`/admin/recipe/edit/${_id}`)
  }, [props.history, _id])

  if (!recipe) {
    return <Redirect to='/404' />
  }
  return (
    <>
      <AbsoluteFab adminOnly={true} onClick={onAbsoluteFabClickMemo}>
        <Edit />
      </AbsoluteFab>
      <RecipePage
        recipe={recipe}
        key={recipe._id}
        rating={getRecipeRating(recipe, userContext.ratings)}
        isPinned={isRecipePinned(recipe, userContext.pinned)}
        isInGroceryList={isRecipeInGroceryList(
          recipe,
          userContext.groceryListItems
        )}
        onToggleGroceryList={async (add) => {
          try {
            if (add) {
              const { groceryListItem } = await addRecipeToGroceryList(
                recipe._id
              )
              userContext.userDispatch(
                userActions.addGroceryListItem(groceryListItem)
              )
            } else {
              await deleteRecipeFromGroceryList(recipe._id)
              userContext.userDispatch(
                userActions.removeGroceryListItem(recipe._id)
              )
            }
          } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' })
          }
        }}
        onTogglePin={async (updatedPinned) => {
          try {
            if (updatedPinned) {
              await pinRecipe(recipe._id)
              userContext.userDispatch(userActions.pinRecipe(recipe._id))
            } else {
              await unpinRecipe(recipe._id)
              userContext.userDispatch(userActions.unpinRecipe(recipe._id))
            }
          } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' })
          }
        }}
        onChangeRating={async (ratingScore, recipe) => {
          try {
            if (ratingScore === 0) {
              const { id: recipeId } = await deleteRating(recipe)
              userContext.userDispatch(userActions.unsetRecipeRating(recipeId))
            } else {
              const { rating } = await updateRating(ratingScore, recipe)
              userContext.userDispatch(userActions.resetRating(rating))
            }
          } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' })
          }
        }}
        onParentRecipeClick={onParentRecipeClickMemo}
      />
    </>
  )
}
