export const getFrequencyMap = (list: string[]): Map<string, number> => {
  return list.reduce((map, item) => {
    map.set(item, (map.get(item) || 0) + 1)
    return map
  }, new Map())
}

export const sortMapByValues = <T>(
  unsortedMap: Map<T, number>
): Map<T, number> =>
  new Map([...unsortedMap.entries()].sort((a, b) => b[1] - a[1]))

export const sortMapByKeys = (
  unsortedMap: Map<string, number>
): Map<string, number> =>
  new Map(
    [...unsortedMap.entries()].sort((a, b) =>
      a[0].toLowerCase().localeCompare(b[0].toLowerCase())
    )
  )

export const uniqueSortedByFrequency = (list: string[]): string[] => {
  const sortedMap = sortMapByValues(getFrequencyMap(list))
  return sortedMap && sortedMap.size ? Array.from(sortedMap.keys()) : []
}
