import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import React, { useCallback, useContext } from 'react'
import { getDefaultAisleId } from '../@aligre/lib/aisles'
import { createSelectOptions } from '../lib/selectOptions'
import { RecipeContext } from '../state/recipes/RecipeProvider'

interface AisleFormControlSelectProps {
  selected: string | undefined
  onChange: (selected: string) => void
}

export default function AisleFormControlSelect(
  props: AisleFormControlSelectProps
) {
  const { aisles } = useContext(RecipeContext)
  const { onChange } = props
  const selected = props.selected || getDefaultAisleId(aisles)

  const onChangeMemo = useCallback(
    (event) => {
      onChange(event.target.value)
    },
    [onChange]
  )
  // @todo not sure it is the best option to not render anything
  if (!selected) return null

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor='select-aisle'>Aisle</InputLabel>
      <Select
        inputProps={{
          id: 'select-aisle',
        }}
        value={selected}
        onChange={onChangeMemo}>
        {createSelectOptions(aisles).map(({ value, label }) => {
          return (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
