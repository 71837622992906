import {
  isIngredientLineWithMainIngredient,
  Recipe,
} from '../../../@aligre/types'
import { sortMapByValues } from '../../../lib/dataSorting'

export default function getMostUsedWithIngredients(
  mainIngredientId: string,
  recipes: Recipe[]
): string[] {
  const mainIngredientsMap = recipes.reduce((map, recipe) => {
    const recipeMainIngredients = recipe.ingredients
      .filter(isIngredientLineWithMainIngredient)
      .map((i) => i.mainIngredient)
    if (recipeMainIngredients.includes(mainIngredientId)) {
      recipeMainIngredients
        .filter((id) => id !== mainIngredientId)
        .forEach((id) => map.set(id, (map.get(id) || 0) + 1))
    }
    return map
  }, new Map<string, number>())
  const sortedMap = sortMapByValues(mainIngredientsMap)
  return sortedMap && sortedMap.size ? Array.from(sortedMap.keys()) : []
}
