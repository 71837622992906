import Cookies from 'js-cookie'
import { ACCESS_TOKEN } from '../constants/cookies'
import { API_ENDPOINT } from '../constants/endpoints'

const redirectOn401 = () => {
  Cookies.remove(ACCESS_TOKEN)
  window.location.href = '/login'
}

export default async function apiFetch<V, T>(
  endpoint: string,
  method: 'PUT' | 'GET' | 'POST' | 'DELETE' = 'GET',
  body?: V
): Promise<T> {
  const accessToken = Cookies.get(ACCESS_TOKEN)

  if (!accessToken && window.location.pathname !== '/login') {
    redirectOn401()
    return Promise.reject()
  }

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    ...(method === 'GET'
      ? {}
      : {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
  }

  const res = await fetch(`${API_ENDPOINT}/${endpoint}`, {
    method,
    headers,
    ...(method !== 'GET' && { body: JSON.stringify(body) }),
  })

  if (res.status === 401) {
    redirectOn401()
    return Promise.reject()
  }
  const responseData = await res.json()
  if (res.status !== 200 || responseData.error || responseData.ok === 0) {
    return Promise.reject(responseData.error || 'An error occured')
  }

  return Promise.resolve(responseData)
}

export const mayBeAuthenticated = () => {
  return Cookies.get(ACCESS_TOKEN) !== undefined
}
