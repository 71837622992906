import Box from '@material-ui/core/Box'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Bookmark from '@material-ui/icons/Bookmark'
import React, { useCallback } from 'react'
import { getRecipePictureUrl } from '../@aligre/lib/recipes'
import { Recipe } from '../@aligre/types'
import useWhyDidIUpdate from '../hooks/useWhyDidIUpdate'

interface RecipeListItemProps {
  recipe: Recipe
  onRecipeClick: (recipe: Recipe) => void
  getSecondaryText: (recipe: Recipe) => string
  isPinned?: boolean
}

const useStyles = makeStyles((theme) => ({
  recipeImg: {
    height: '60px',
    width: '60px',
  },
}))

const RecipeListItem = (props: RecipeListItemProps) => {
  const classes = useStyles()
  const { recipe, onRecipeClick, getSecondaryText } = props

  const onRecipeClickMemo = useCallback(() => {
    onRecipeClick(recipe)
  }, [recipe, onRecipeClick])

  useWhyDidIUpdate('RecipeListItem', props)
  useWhyDidIUpdate('RecipeListItem memo', { onRecipeClickMemo })
  return (
    <ListItem disableGutters divider dense button onClick={onRecipeClickMemo}>
      <Box mr={2}>
        <img
          className={classes.recipeImg}
          src={getRecipePictureUrl(recipe)}
          alt={recipe.name}
        />
      </Box>
      <ListItemText
        primary={recipe.name}
        secondary={getSecondaryText(recipe)}
      />
      {props.isPinned && (
        <Box position='absolute' top={-2} right={20}>
          <Bookmark />
        </Box>
      )}
    </ListItem>
  )
}
RecipeListItem.defaultProps = {
  isPinned: false,
}

export default React.memo(RecipeListItem)
