import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import React, { useContext, useMemo } from 'react'
import { getAisleName } from '../../@aligre/lib/aisles'
import { getMainIngredientAllPreps } from '../../@aligre/lib/ingredientLines/getAllVerbsAndPreps'
import {
  findMainIngredientsById,
  getMainIngredientParentRecipes,
} from '../../@aligre/lib/mainIngredients'
import {} from '../../@aligre/lib/recipes'
import { getMainIngredientMonthlySeasonScore } from '../../@aligre/lib/seasonality/season'
import { filterUndefined, MainIngredient, Recipe } from '../../@aligre/types'
import MainIngredientListItem from '../../components/MainIngredientListItem'
import RecipeList from '../../components/RecipeList'
import SeasonTable from '../../components/SeasonTable'
import useScrollToTop from '../../hooks/useScrollToTop'
import { RecipeContext } from '../../state/recipes/RecipeProvider'
import { UserContext } from '../../state/user/UserProvider'
import getMainIngredientPrepForRecipe from './lib/getMainIngredientPrepForRecipe'
import getMostUsedWithIngredients from './lib/getMostUsedWithIngredients'

interface IngredientPageProps {
  mainIngredient: MainIngredient
  onRecipeClick: (recipe: Recipe) => void
  onIngredientClick: (ingredient: MainIngredient) => void
}

export default function IngredientPage(props: IngredientPageProps) {
  const userContext = useContext(UserContext)
  useScrollToTop()
  const { allRecipes, aisles, allMainIngredients } = useContext(RecipeContext)
  const { _id: mainIngredientId, name } = props.mainIngredient
  const seasonality = useMemo(() => {
    return getMainIngredientMonthlySeasonScore(props.mainIngredient, allRecipes)
  }, [props.mainIngredient, allRecipes])

  const recipes = useMemo(
    () => getMainIngredientParentRecipes(mainIngredientId, allRecipes),
    [allRecipes, mainIngredientId]
  )
  const prepModes = useMemo(
    () => getMainIngredientAllPreps(allRecipes, mainIngredientId),
    [allRecipes, mainIngredientId]
  )

  const mostUsedWithIngredients = useMemo(
    () =>
      filterUndefined(
        findMainIngredientsById(
          getMostUsedWithIngredients(mainIngredientId, allRecipes),
          allMainIngredients
        )
      ),
    [allRecipes, allMainIngredients, mainIngredientId]
  )

  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <Typography variant='h4' component='h1'>
          {name}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {getAisleName(props.mainIngredient.aisle, aisles)}
      </Grid>

      <Grid item xs={12}>
        {seasonality && <SeasonTable seasonality={seasonality} />}
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography variant='h5'>Recipes ({recipes.length})</Typography>
        <RecipeList
          recipes={recipes}
          pinned={userContext.pinned}
          scrollableHeight={500}
          onRecipeClick={(recipe) => props.onRecipeClick(recipe)}
          getSecondaryText={(recipe) =>
            getMainIngredientPrepForRecipe(recipe, mainIngredientId)
          }
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography variant='h5'>Prep Mode</Typography>
        {prepModes.map((prepMode) => (
          <Typography variant='body1' key={prepMode}>
            {prepMode}
          </Typography>
        ))}
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography variant='h5'>Most Used With</Typography>
        <List>
          {mostUsedWithIngredients.slice(0, 12).map((ingredient) => (
            <MainIngredientListItem
              key={ingredient._id}
              ingredient={ingredient}
              onIngredientClick={props.onIngredientClick}
            />
          ))}
        </List>
      </Grid>
    </Grid>
  )
}
