/**
 * @private
 * @param str A verb
 * @category prep
 */
export const verbToSingular = (str: string) =>
  str.trim().toLowerCase().replace(/e?s?$/, '')

/**
 * @internal
 */
const regExpPrepVerbs = /([A-Za-zàâçéèêëîïôûùüÿñæœ]+[é|i]e?s?)(?:,?\s|$)/

/**
 * @internal
 */
const regExpPrepVerbs3rd = /(cuite?s?|battue?s?|frite?s?|fondue?s?|moulue?s?|fendue?s?)\b/

/**
 * @private
 */
export const getFirstVerbOccurrence = (str: string) => {
  const matches = str.match(regExpPrepVerbs)
  return matches ? matches[1] : null
}

/**
 * @private
 */
export const getFirst3rdVerbOccurrence = (str: string) => {
  const matches = str.match(regExpPrepVerbs3rd)
  return matches ? matches[1] : null
}

/**
 * @internal
 */
const invalid = [
  'biais',
  'puis',
  'rosé',
  'pincée',
  'bois',
  'café',
  'basmati',
  'pois',
  'coulis',
  'blé',
  'kiwi',
  'frais',
  'céleri',
  'radis',
  'paris',
  'kaloupilé',
  'shiitakés',
  'chili',
  'cannelini',
  'bocconcini',
  'panais',
  'chinois',
  'brie',
  'purée',
  'shimeji',
  'brebis',
  'comté',
  'libanais',
  'thé',
  'demi',
  'emploi',
  'dashi',
  'brocolis',
  'Qui',
  'spaghettis',
  'anchois',
  'mini',
  'ktipiti',
  'variétés',
  'baies',
  'kiwis',
  'goji',
  'wakamé',
  'salsifis',
  'gnocchis',
  'teriyaki',
  'sushi',
  'nori',
  'tortellinis',
  'épis',
  'anis',
  'Dauphiné',
  'Ditalini',
  'Enoki',
  'tamari',
  'tandoori',
  'dés',
  'frisée',
  'ambré',
  'vanillé',
  'germé',
  'mariné',
  'concentré',
  'allégée',
  'affiné',
  'pommé',
  'cassé',
  'perlé',
  'poivré',
  'anisé',
  'enrichi',
  'sucré',
  'acidulé',
  'écrémé',
  'traité',
  'salé',
  'sablé',
  'brisé',
  'feuilleté',
  'doré',
  'garni',
  'fumé',
  'séché',
  'fermenté',
  'surgelées',
  'mélangé',
  'effilé',
  'moulu',
  'rassis',
].map(verbToSingular)

/**
 * Is it a valid verb?
 * @category prep
 */
export const isVerbValid = (verb: string) =>
  !invalid.includes(verbToSingular(verb))
