import { IngredientLineWithMainIngredient, IngredientLineWithoutMainIngredient, isIngredientLineWithMainIngredient, isSeparatorLine, SeparatorLine } from '../../types'
import { isPrepValid } from './utils/prep'
import { isQuantityValid } from './utils/quantity'
import { isUnityValid } from './utils/unity'

export { isPrepValid, isQuantityValid, isUnityValid }
/**
 * Is raw ingredient valid?
 * @param ingredient
 * @category ingredientLine
 */
export const isRawIngredientValid = (ingredient: string): boolean => {
  return (
    ingredient.length > 0 &&
    ingredient.trim().length === ingredient.length &&
    !ingredient.includes(',') &&
    !ingredient.includes('.')
  )
}

/**
 * Is ingredient line a valid IngredientLine or SeparatorLine
 * @category ingredientLine
 */
const isIngredientLineValid = (
  ingredient:
    | SeparatorLine
    | IngredientLineWithMainIngredient
    | IngredientLineWithoutMainIngredient
): ingredient is SeparatorLine | IngredientLineWithMainIngredient => {
  if (isSeparatorLine(ingredient)) {
    return ingredient.line.length > 0
  }
  if (isIngredientLineWithMainIngredient(ingredient)) {
    return (
      isRawIngredientValid(ingredient.ingredient) &&
      isUnityValid(ingredient.unity) &&
      isQuantityValid(ingredient.quantity) &&
      isPrepValid(ingredient.prep)
    )
  }
  return false
}

export default isIngredientLineValid
