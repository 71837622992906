import {
  filterUndefined,
  IngredientLineWithMainIngredient,
  IngredientLineWithoutMainIngredient,
  isIngredientLineWithMainIngredient,
  MainIngredient,
  MainIngredientDict,
  MonthlySeasonScore,
  notUndefined,
  Recipe,
  SeasonScore,
  SeparatorLine,
} from '../../types'
import {
  findMainIngredientById,
  findMainIngredientsById,
} from '../mainIngredients'
import { findRecipeById } from '../recipes'

/**
 * @private
 * @category seasonScore
 */
export const mergeMonthlySeasonScores = (
  scoreArr: MonthlySeasonScore[]
): MonthlySeasonScore => {
  if (scoreArr.length === 0)
    return Array(12).fill(SeasonScore.Perfect) as MonthlySeasonScore
  return scoreArr.reduce((acc: MonthlySeasonScore, f: MonthlySeasonScore) => {
    const monthlyScore = acc.map((t: SeasonScore, index) => {
      if (t === SeasonScore.NoGo || f[index] === SeasonScore.NoGo)
        return SeasonScore.NoGo
      if (t === SeasonScore.Perfect && f[index] === SeasonScore.Perfect)
        return SeasonScore.Perfect
      return SeasonScore.Ok
    })
    return monthlyScore as MonthlySeasonScore
  }, new Array(12).fill(SeasonScore.Perfect) as MonthlySeasonScore)
}

/**
 * Get the monthly season score for a given main ingredient
 * @category seasonScore
 */
export const getMainIngredientMonthlySeasonScore = (
  mainIngredient: MainIngredient | undefined,
  allRecipes: Recipe[]
): MonthlySeasonScore | undefined => {
  if (mainIngredient) {
    if (mainIngredient.seasonality) {
      return mainIngredient.seasonality
    } else if (mainIngredient.recipe) {
      const r = findRecipeById(mainIngredient.recipe, allRecipes)
      if (r && r.seasonality) return r.seasonality
    }
  }
  return undefined
}

/**
 * Get the main ingredient season score for a given month
 * If no recipes dict is passed and if the main ingredient
 * is a nested recipe, undefined is returned
 * @category seasonScore
 */
export const getMainIngredientMonthSeasonScore = (
  mainIngredient: MainIngredient | undefined,
  month: number | undefined,
  allRecipes: Recipe[] = []
): SeasonScore | undefined => {
  const seasonality = getMainIngredientMonthlySeasonScore(
    mainIngredient,
    allRecipes
  )
  return Array.isArray(seasonality) && month !== undefined
    ? seasonality[month]
    : undefined
}

/**
 * Get the recipe season score for a given month
 * @category seasonScore
 */
export const getRecipeMonthSeasonScore = (
  recipe: Recipe,
  month: number
): SeasonScore | undefined => recipe.seasonality[month]

/**
 * Compute the montly season score for a set of ingredients
 * @category seasonScore
 */
export const computeMonthlySeasonScore = (
  ingredients: (
    | SeparatorLine
    | IngredientLineWithMainIngredient
    | IngredientLineWithoutMainIngredient
  )[],
  recipes: Recipe[],
  allMainIngredients: MainIngredientDict
): MonthlySeasonScore => {
  const ingredientIds = ingredients
    .filter(isIngredientLineWithMainIngredient)
    .map((i) => i.mainIngredient)

  const mainIngredients = filterUndefined(
    findMainIngredientsById(ingredientIds, allMainIngredients)
  )

  const ingredientsSeasonRatingArr = mainIngredients
    .map(({ _id: mainIngredientId }) =>
      getMainIngredientMonthlySeasonScore(
        findMainIngredientById(mainIngredientId, allMainIngredients),
        recipes
      )
    )
    .filter(notUndefined)

  return mergeMonthlySeasonScores(ingredientsSeasonRatingArr)
}
