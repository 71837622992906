import {
  ClientUser,
  IngredientLineWithMainIngredient,
  RegisteredUser,
  UserPantryDict,
} from '../../types'

/**
 * Is user an admin
 * @category user
 */
export function isAdmin(user: ClientUser | RegisteredUser) {
  return user.role === 'admin'
}

/**
 * Is ingredient line in user pantry
 * @category pantry
 */
export const isIngredientLineInPantry = (
  ingredient: IngredientLineWithMainIngredient,
  pantry: UserPantryDict
) => pantry[ingredient.mainIngredient]
