import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Bookmark from '@material-ui/icons/Bookmark'
import BookmarkBorder from '@material-ui/icons/BookmarkBorder'
import Cancel from '@material-ui/icons/Cancel'
import Edit from '@material-ui/icons/Edit'
import LocalGroceryStore from '@material-ui/icons/LocalGroceryStore'
import Save from '@material-ui/icons/Save'
import Rating from '@material-ui/lab/Rating'
import React, { useContext, useMemo, useState } from 'react'
import { prebuildIngredientLine } from '../../@aligre/lib/ingredientLines/buildIngredientLine'
import {
  findMainIngredientById,
  getMainIngredientParentRecipes,
} from '../../@aligre/lib/mainIngredients'
import {
  getRecipeAssociatedMainIngredientIdIfNested,
  getRecipeCategories,
  getRecipePictureUrl,
  getRecipeSource,
} from '../../@aligre/lib/recipes'
import { getMainIngredientMonthSeasonScore } from '../../@aligre/lib/seasonality/season'
import { isIngredientLineInPantry } from '../../@aligre/lib/users'
import {
  IngredientLineWithMainIngredient,
  isSeparatorLine,
  NoRatingScore,
  RatingScore,
  Recipe,
  SeparatorLine,
} from '../../@aligre/types'
import LinkInternal from '../../components/LinkInternal'
import RecipeList from '../../components/RecipeList'
import SeasonTable from '../../components/SeasonTable'
import seasonScoreColors from '../../constants/colors'
import useScrollToTop from '../../hooks/useScrollToTop'
import useWhyDidIUpdate from '../../hooks/useWhyDidIUpdate'
import { RecipeContext } from '../../state/recipes/RecipeProvider'
import { SearchContext } from '../../state/search/SearchProvider'
import { UserContext } from '../../state/user/UserProvider'

const useStyles = makeStyles((theme) => ({
  recipeImg: {
    maxWidth: '100%',
    height: 'auto',
    width: '120px',
  },
  noSeasonScore: {
    color: theme.palette.text.primary,
  },
  seasonScore0: {
    color: seasonScoreColors[0],
  },
  seasonScore1: {
    color: seasonScoreColors[1],
  },
  seasonScore2: {
    color: seasonScoreColors[2],
  },
  inPantry: {
    opacity: 0.6,
  },
}))

interface RecipeProps {
  recipe: Recipe
  rating: RatingScore | NoRatingScore
  isPinned: boolean
  onTogglePin: (pinned: boolean) => void
  isInGroceryList: boolean
  onToggleGroceryList: (add: boolean) => void
  onChangeRating: (
    ratingScore: RatingScore | NoRatingScore,
    recipe: Recipe
  ) => void
  onParentRecipeClick: (recipe: Recipe) => void
}

export default function RecipePage(props: RecipeProps) {
  const classes = useStyles()
  const {
    allSources,
    allCategories,
    allMainIngredients,
    allRecipes,
  } = useContext(RecipeContext)
  const { pantry, pinned } = useContext(UserContext)
  const { selectedMonth } = useContext(SearchContext)
  useScrollToTop()
  const [tab, setTab] = useState(0)
  const [ratingReadOnly, setRatingReadOnly] = useState(true)
  const [ratingScore, setRatingScore] = useState<RatingScore | NoRatingScore>(
    props.rating || 0
  )

  const upSM = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  const parentRecipes = useMemo(() => {
    const mainIngredientId = getRecipeAssociatedMainIngredientIdIfNested(
      props.recipe._id,
      allMainIngredients
    )
    return mainIngredientId !== null
      ? getMainIngredientParentRecipes(mainIngredientId, allRecipes)
      : null
  }, [props.recipe._id, allMainIngredients, allRecipes])

  useWhyDidIUpdate('RecipePage', props)
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h4' component='h1'>
          {props.recipe.name}
        </Typography>
        <Checkbox
          icon={<BookmarkBorder />}
          checkedIcon={<Bookmark />}
          checked={props.isPinned}
          onChange={(event) => props.onTogglePin(event.target.checked)}
        />
        <Checkbox
          icon={<LocalGroceryStore />}
          checkedIcon={<LocalGroceryStore />}
          checked={props.isInGroceryList}
          onChange={(event) => props.onToggleGroceryList(event.target.checked)}
        />
      </Grid>
      <Grid item xs={4}>
        <Box mr={2}>
          <img
            className={classes.recipeImg}
            src={getRecipePictureUrl(props.recipe)}
            alt={props.recipe.name}
          />
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Rating
          name='rating'
          value={ratingScore}
          onChange={async (e, value) => {
            const ratingScore = value as RatingScore
            setRatingScore(ratingScore)
          }}
          readOnly={ratingReadOnly}
        />
        {ratingReadOnly ? (
          <IconButton
            aria-label='edit'
            onClick={() => {
              setRatingReadOnly(false)
            }}>
            <Edit />
          </IconButton>
        ) : (
          <>
            <IconButton
              aria-label='cancel'
              onClick={() => {
                setRatingScore(0 as NoRatingScore)
              }}>
              <Cancel />
            </IconButton>
            <IconButton
              aria-label='save'
              onClick={() => {
                setRatingReadOnly(true)
                props.onChangeRating(ratingScore, props.recipe)
              }}>
              <Save />
            </IconButton>
          </>
        )}
        <Typography variant='subtitle1'>
          {getRecipeCategories(props.recipe, allCategories).join(', ')}
        </Typography>
        {props.recipe.sourceUrl ? (
          <Link href={props.recipe.sourceUrl} variant='subtitle2'>
            {getRecipeSource(props.recipe, allSources)}
          </Link>
        ) : (
          <Typography variant='subtitle2'>
            {getRecipeSource(props.recipe, allSources)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={4}>
        {Boolean(props.recipe.servings) && (
          <Typography variant='subtitle2'>
            Servings: <b>{props.recipe.servings}</b>
          </Typography>
        )}
      </Grid>
      <Grid item xs={4}>
        {Boolean(props.recipe.prepTime) && (
          <Typography variant='subtitle2'>
            Prep Time: <b>{props.recipe.prepTime} minutes</b>
          </Typography>
        )}
      </Grid>
      <Grid item xs={4}>
        {Boolean(props.recipe.cookTime) && (
          <Typography variant='subtitle2'>
            Cook Time: <b>{props.recipe.cookTime} minutes</b>
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        {props.recipe.seasonality && (
          <SeasonTable seasonality={props.recipe.seasonality} />
        )}
      </Grid>
      {!upSM && (
        <Grid item xs={12}>
          <Tabs
            value={tab}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
            onChange={(event, newValue: number) => {
              setTab(newValue)
            }}>
            <Tab label='Ingredients' />
            <Tab label='Directions' />
          </Tabs>
        </Grid>
      )}
      {(tab === 0 || upSM) && (
        <Grid item xs={12} sm={4}>
          {upSM && <Typography variant='h4'>Ingredients</Typography>}
          <Box mt={2}>
            {props.recipe.ingredients.map(
              (i: SeparatorLine | IngredientLineWithMainIngredient, key) => {
                if (isSeparatorLine(i)) {
                  return (
                    <Box fontWeight='fontWeightMedium' key={key}>
                      <Typography variant='body1'>{i.line}</Typography>
                    </Box>
                  )
                }

                const humanized = prebuildIngredientLine(i)
                const mainIngredient = findMainIngredientById(
                  i.mainIngredient,
                  allMainIngredients
                )
                if (!mainIngredient) return null

                const seasonScore = getMainIngredientMonthSeasonScore(
                  mainIngredient,
                  selectedMonth,
                  allRecipes
                )

                const isInPantry = isIngredientLineInPantry(i, pantry)
                const classNames = `${
                  //@ts-ignore
                  classes[
                    seasonScore !== undefined
                      ? ['seasonScore0', 'seasonScore1', 'seasonScore2'][
                          seasonScore
                        ]
                      : 'noSeasonScore'
                  ]
                } ${isInPantry ? classes['inPantry'] : ''}`

                return (
                  <Box
                    key={key}
                    color={isInPantry ? 'text.hint' : 'text.primary'}>
                    {humanized.quantity && (
                      <Typography variant='body1' display='inline'>
                        {humanized.quantity}
                      </Typography>
                    )}
                    {humanized.unity && (
                      <Typography variant='body1' display='inline'>
                        {humanized.unity}
                      </Typography>
                    )}
                    <Typography variant='body1' display='inline'>
                      <LinkInternal
                        to={`/ingredient/${mainIngredient._id}`}
                        className={classNames}>
                        {humanized.ingredient}
                      </LinkInternal>
                    </Typography>
                    {humanized.prep && (
                      <Typography variant='body1' display='inline'>
                        {humanized.prep}
                      </Typography>
                    )}
                  </Box>
                )
              }
            )}
          </Box>
        </Grid>
      )}
      {(tab === 1 || upSM) && (
        <Grid item xs={12} sm={8}>
          {upSM && <Typography variant='h4'>Directions</Typography>}
          <Box mt={2}>
            {props.recipe.directions.split('\n').map((item: string, key) => (
              <Typography variant='body1' key={key}>
                {item}
                <br />
              </Typography>
            ))}
          </Box>
        </Grid>
      )}
      {Array.isArray(parentRecipes) && (
        <Grid item xs={12}>
          <Typography variant='h4'>
            {parentRecipes.length} parent Recipes
          </Typography>
          <RecipeList
            recipes={parentRecipes}
            pinned={pinned}
            scrollableHeight={500}
            onRecipeClick={props.onParentRecipeClick}
            getSecondaryText={() => ''}
          />
        </Grid>
      )}
    </Grid>
  )
}
