import Avatar from '@material-ui/core/Avatar'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Visibility from '@material-ui/icons/Visibility'
import React, { useCallback, useContext } from 'react'
import { getMainIngredientMonthSeasonScore } from '../@aligre/lib/seasonality/season'
import { isMainIngredientNestedRecipe, MainIngredient } from '../@aligre/types'
import seasonScoreColors from '../constants/colors'
import useWhyDidIUpdate from '../hooks/useWhyDidIUpdate'
import { RecipeContext } from '../state/recipes/RecipeProvider'

interface MainIngredientListItemProps {
  ingredient: MainIngredient
  month?: number
  selected?: boolean
  onIngredientClick: (ingredient: MainIngredient) => void
  onIngredientSelect?: (ingredient: MainIngredient, selected: boolean) => void
  onIconClick?: (ingredient: MainIngredient) => void
  icon?: JSX.Element
}

const common = {
  color: '#fff',
}
const useStyles = makeStyles((theme) => ({
  noSeasonScore: {
    ...common,
    backgroundColor: theme.palette.grey[400],
  },
  seasonScore0: {
    ...common,
    backgroundColor: seasonScoreColors[0],
  },
  seasonScore1: {
    ...common,
    backgroundColor: seasonScoreColors[1],
  },
  seasonScore2: {
    ...common,
    backgroundColor: seasonScoreColors[2],
  },
}))

function noop() {}

export function MainIngredientListItem(props: MainIngredientListItemProps) {
  const { allRecipes } = useContext(RecipeContext)
  const seasonScore = getMainIngredientMonthSeasonScore(
    props.ingredient,
    props.month,
    allRecipes
  )
  const classes = useStyles(props)

  useWhyDidIUpdate('MainIngredientListItem', props)
  const {
    ingredient,
    onIngredientClick,
    onIconClick,
    onIngredientSelect,
  } = props

  const onClickMemo = useCallback(() => onIngredientClick(ingredient), [
    ingredient,
    onIngredientClick,
  ])

  const onIconClickMemo = useCallback(
    () =>
      typeof onIconClick === 'function' ? onIconClick(ingredient) : noop(),
    [ingredient, onIconClick]
  )

  return (
    <ListItem dense divider button onClick={onClickMemo}>
      {props.month !== undefined && (
        <ListItemAvatar>
          <Avatar
            className={
              //@ts-ignore
              classes[
                seasonScore !== undefined
                  ? ['seasonScore0', 'seasonScore1', 'seasonScore2'][
                      seasonScore
                    ]
                  : 'noSeasonScore'
              ]
            }>
            {isMainIngredientNestedRecipe(ingredient) ? 'R' : 'I'}
          </Avatar>
        </ListItemAvatar>
      )}
      {onIngredientSelect !== undefined && (
        <Checkbox
          checked={props.selected || false}
          onClick={(event) => {
            event.stopPropagation()
          }}
          onChange={(event) => {
            onIngredientSelect(ingredient, event.target.checked)
          }}
          value='selected'
        />
      )}
      <ListItemText>{ingredient.name}</ListItemText>
      {onIconClick !== undefined && (
        <ListItemSecondaryAction>
          <IconButton onClick={onIconClickMemo}>
            {props.icon ? props.icon : <Visibility />}
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  )
}
export default React.memo(MainIngredientListItem)
