import Grid from '@material-ui/core/Grid'
import SearchIcon from '@material-ui/icons/Search'
import React, { useCallback, useContext, useMemo } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { getMainIngredientMonthSeasonScore } from '../../@aligre/lib/seasonality/season'
import {
  isMainIngredientNestedRecipe,
  MainIngredient,
  SeasonScore,
} from '../../@aligre/types'
import MainIngredientListByAisle from '../../components/MainIngredientListByAisle'
import { RecipeContext } from '../../state/recipes/RecipeProvider'
import { SearchContext } from '../../state/search/SearchProvider'

interface SeasonalProduceContainerProps extends RouteComponentProps {}

export default function SeasonalProduceContainer(
  props: SeasonalProduceContainerProps
) {
  const { allMainIngredients } = useContext(RecipeContext)
  const {
    selectedMainIngredients,
    setSelectedMainIngredients,
    selectedMonth,
  } = useContext(SearchContext)

  const freshIngredientsMap = useMemo(() => {
    return Object.values(allMainIngredients).reduce((map, ingredient) => {
      if (!isMainIngredientNestedRecipe(ingredient)) {
        const monthSeasonScore = getMainIngredientMonthSeasonScore(
          ingredient,
          selectedMonth
        )
        if (monthSeasonScore !== undefined) {
          map.set(monthSeasonScore, [
            ...(map.get(monthSeasonScore) || []),
            ingredient,
          ])
        }
      }
      return map
    }, new Map<SeasonScore, MainIngredient[]>())
  }, [allMainIngredients, selectedMonth])

  const freshIngredients = [
    ...(freshIngredientsMap.get(2) || []),
    ...(freshIngredientsMap.get(1) || []),
  ]

  const onIngredientClickMemo = useCallback(
    (mainIngredient) => {
      props.history.push(`/ingredient/${mainIngredient._id}`)
    },
    [props.history]
  )

  const onIconClickMemo = useCallback(
    (ingredient) => {
      if (!selectedMainIngredients.includes(ingredient._id)) {
        setSelectedMainIngredients([...selectedMainIngredients, ingredient._id])
      }

      props.history.push('/')
    },
    [setSelectedMainIngredients, selectedMainIngredients, props.history]
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        <MainIngredientListByAisle
          mainIngredients={freshIngredients}
          month={selectedMonth}
          onIngredientClick={onIngredientClickMemo}
          onIconClick={onIconClickMemo}
          icon={<SearchIcon />}
        />
      </Grid>
    </Grid>
  )
}
