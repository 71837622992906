export type SeparatorLine = {
  line: string
  kind?: string
}

export type IngredientLineWithoutMainIngredient = {
  unity: string
  quantity: string
  ingredient: string
  prep: string
  kind?: string
}

export type IngredientLineWithMainIngredient = IngredientLineWithoutMainIngredient & {
  mainIngredient: string
}

/**
 * Is ingredient a separator?
 * @category ingredientLine
 */
export function isSeparatorLine(
  ingredient:
    | SeparatorLine
    | IngredientLineWithMainIngredient
    | IngredientLineWithoutMainIngredient
): ingredient is SeparatorLine {
  return typeof (ingredient as SeparatorLine).line === 'string'
}

/**
 * Is ingredient an ingredient line including a main ingredient?
 * @category ingredientLine
 */
export function isIngredientLineWithMainIngredient(
  ingredient:
    | SeparatorLine
    | IngredientLineWithMainIngredient
    | IngredientLineWithoutMainIngredient
): ingredient is IngredientLineWithMainIngredient {
  return (
    typeof (ingredient as IngredientLineWithMainIngredient).mainIngredient ===
    'string'
  )
}
