import React, { useReducer } from 'react'
import useWhyDidIUpdate from '../../hooks/useWhyDidIUpdate'
import recipeReducer, {
  initialState as recipeInitialState,
  RecipeAction,
  RecipeState,
} from './recipeReducer'

const RecipeContext = React.createContext<
  RecipeState & {
    recipeDispatch: React.Dispatch<RecipeAction>
  }
>({
  ...recipeInitialState,
  recipeDispatch: () => {},
})

interface RecipeProviderProps {
  children: any
}
function RecipeProvider(props: RecipeProviderProps) {
  console.count('[render] RecipeProvider')
  const [
    { allRecipes, allSources, allCategories, allMainIngredients, aisles },
    recipeDispatch,
  ] = useReducer(recipeReducer, recipeInitialState)
  useWhyDidIUpdate('RecipeProvider', props)
  useWhyDidIUpdate('RecipeProvider Internal State', {
    allRecipes,
    allSources,
    allCategories,
    allMainIngredients,
    aisles,
  })
  return (
    <RecipeContext.Provider
      value={{
        allRecipes,
        allSources,
        allCategories,
        allMainIngredients,
        aisles,
        recipeDispatch,
      }}>
      {props.children}
    </RecipeContext.Provider>
  )
}
export { RecipeContext, RecipeProvider }
