import { useEffect, useRef } from 'react'

interface GenericProps {
  [key: string]: any
}

function useWhyDidIUpdate(name: string, props: GenericProps) {
  const previousPropsRef = useRef<GenericProps | null>(null)

  useEffect(() => {
    if (previousPropsRef !== null && previousPropsRef.current !== null) {
      const previousProps = previousPropsRef.current

      const allKeys = Object.keys({ ...previousProps, ...props })

      const changedProps = allKeys.reduce(
        (acc: Partial<GenericProps>, key: string): Partial<GenericProps> => {
          if (previousProps[key] !== props[key]) {
            acc[key] = {
              from: previousProps[key],
              to: props[key],
            }
          }
          return acc
        },
        {}
      )

      if (Object.keys(changedProps).length) {
        console.debug('[why-did-you-update]', name, changedProps)
      }
    }

    previousPropsRef.current = props
  })
}

function noop() {}

export default (process.env.NODE_ENV === 'production' ? noop : useWhyDidIUpdate)
