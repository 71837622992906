import {
  ClientUser,
  RecipeGroceryList,
  RecipeGroceryListDict,
  RecipeRating,
  RecipeRatingDict,
  RegisteredUser,
  UserPantryDict,
  UserPinDict,
} from '../../@aligre/types'
import { FetchUserResponse } from '../../@aligre/types/api'

// --- TYPES

enum ACTION_TYPES {
  RESET_USER = 'RESET_USER',
  SET_RATING = 'SET_RATING',
  DELETE_RATING = 'DELETE_RATING',
  ADD_GROCERY_LIST_ITEM = 'ADD_GROCERY_LIST_ITEM',
  REMOVE_GROCERY_LIST_ITEM = 'REMOVE_GROCERY_LIST_ITEM',
  REMOVE_PANTRY_ITEM = 'REMOVE_PANTRY_ITEM',
  ADD_PANTRY_ITEM = 'ADD_PANTRY_ITEM',
  BATCH_ADD_PANTRY_ITEM = 'BATCH_ADD_PANTRY_ITEM',
  ADD_PIN = 'ADD_PIN',
  REMOVE_PIN = 'REMOVE_PIN',
}

// --- ACTIONS

function resetUser(payload: FetchUserResponse) {
  return {
    type: ACTION_TYPES.RESET_USER as ACTION_TYPES.RESET_USER,
    ...payload,
  }
}

function batchAddToPantry(mainIngredientIds: string[]) {
  return {
    type: ACTION_TYPES.BATCH_ADD_PANTRY_ITEM as ACTION_TYPES.BATCH_ADD_PANTRY_ITEM,
    mainIngredientIds,
  }
}

function addToPantry(mainIngredientId: string) {
  return {
    type: ACTION_TYPES.ADD_PANTRY_ITEM as ACTION_TYPES.ADD_PANTRY_ITEM,
    mainIngredientId,
  }
}

function removeFromPantry(mainIngredientId: string) {
  return {
    type: ACTION_TYPES.REMOVE_PANTRY_ITEM as ACTION_TYPES.REMOVE_PANTRY_ITEM,
    mainIngredientId,
  }
}

function resetRating(rating: RecipeRating) {
  return {
    type: ACTION_TYPES.SET_RATING as ACTION_TYPES.SET_RATING,
    rating,
  }
}

function unsetRecipeRating(recipe: string) {
  return {
    type: ACTION_TYPES.DELETE_RATING as ACTION_TYPES.DELETE_RATING,
    recipe,
  }
}

function addGroceryListItem(groceryListItem: RecipeGroceryList) {
  return {
    type: ACTION_TYPES.ADD_GROCERY_LIST_ITEM as ACTION_TYPES.ADD_GROCERY_LIST_ITEM,
    groceryListItem,
  }
}

function removeGroceryListItem(recipe: string) {
  return {
    type: ACTION_TYPES.REMOVE_GROCERY_LIST_ITEM as ACTION_TYPES.REMOVE_GROCERY_LIST_ITEM,
    recipe,
  }
}

function pinRecipe(recipeId: string) {
  return {
    type: ACTION_TYPES.ADD_PIN as ACTION_TYPES.ADD_PIN,
    recipeId,
  }
}

function unpinRecipe(recipeId: string) {
  return {
    type: ACTION_TYPES.REMOVE_PIN as ACTION_TYPES.REMOVE_PIN,
    recipeId,
  }
}

export type UserAction =
  | ReturnType<typeof resetUser>
  | ReturnType<typeof resetRating>
  | ReturnType<typeof unsetRecipeRating>
  | ReturnType<typeof addToPantry>
  | ReturnType<typeof batchAddToPantry>
  | ReturnType<typeof removeFromPantry>
  | ReturnType<typeof addGroceryListItem>
  | ReturnType<typeof removeGroceryListItem>
  | ReturnType<typeof pinRecipe>
  | ReturnType<typeof unpinRecipe>

export const userActions = {
  reset: resetUser,
  resetRating,
  unsetRecipeRating,
  addToPantry,
  batchAddToPantry,
  removeFromPantry,
  addGroceryListItem,
  removeGroceryListItem,
  pinRecipe,
  unpinRecipe,
}

export type UserState = (RegisteredUser | ClientUser) & {
  ratings: RecipeRatingDict
  groceryListItems: RecipeGroceryListDict
  pantry: UserPantryDict
  pinned: UserPinDict
}
// --- REDUCER

const clientUser: ClientUser = {
  role: 'client',
}

export const initialState: UserState = {
  ...clientUser,
  ratings: {},
  groceryListItems: {},
  pantry: {},
  pinned: {},
}

export default function reducer(
  state: UserState = initialState,
  action: UserAction
): UserState {
  switch (action.type) {
    case ACTION_TYPES.RESET_USER:
      if (action.user) {
        return {
          ...initialState,
          ...action.user,
          ...(action.ratings && {
            ratings: action.ratings.reduce((acc, r) => {
              return { ...acc, ...{ [r.recipe]: r } }
            }, {} as RecipeRatingDict),
          }),
          ...(action.groceryListItems && {
            groceryListItems: action.groceryListItems.reduce((acc, g) => {
              return { ...acc, ...{ [g.recipe]: true } }
            }, {} as RecipeGroceryListDict),
          }),
          ...(action.pantry && {
            pantry: action.pantry.reduce((acc, i) => {
              return { ...acc, ...{ [i.mainIngredient]: true } }
            }, {} as UserPantryDict),
          }),
          ...(action.pinned && {
            pinned: action.pinned.reduce((acc, p) => {
              return { ...acc, ...{ [p.recipe]: true } }
            }, {} as UserPantryDict),
          }),
        }
      }
      return initialState
    case ACTION_TYPES.DELETE_RATING:
      const { [action.recipe]: value, ...ratingsLeft } = state.ratings
      return { ...state, ratings: ratingsLeft }
    case ACTION_TYPES.SET_RATING:
      const newRatings = {
        ...state.ratings,
        [action.rating.recipe]: action.rating,
      }
      return { ...state, ratings: newRatings }
    case ACTION_TYPES.REMOVE_GROCERY_LIST_ITEM:
      const {
        [action.recipe]: groceryListItemToRemove,
        ...left
      } = state.groceryListItems
      return { ...state, groceryListItems: left }
    case ACTION_TYPES.ADD_GROCERY_LIST_ITEM:
      const newGroceryListItems = {
        ...state.groceryListItems,
        [action.groceryListItem.recipe]: true,
      }
      return { ...state, groceryListItems: newGroceryListItems }
    case ACTION_TYPES.ADD_PANTRY_ITEM:
      const newPantryItems = {
        ...state.pantry,
        [action.mainIngredientId]: true,
      }
      return { ...state, pantry: newPantryItems }
    case ACTION_TYPES.BATCH_ADD_PANTRY_ITEM:
      const newPantryItemsBatch = action.mainIngredientIds.reduce(
        (acc, id) => ({ ...acc, [id]: true }),
        state.pantry
      )
      return { ...state, pantry: newPantryItemsBatch }
    case ACTION_TYPES.REMOVE_PANTRY_ITEM:
      const {
        [action.mainIngredientId]: pantryItemToRemove,
        ...leftPantry
      } = state.pantry
      return { ...state, pantry: leftPantry }

    case ACTION_TYPES.ADD_PIN:
      const newPins = {
        ...state.pinned,
        [action.recipeId]: true,
      }
      return { ...state, pinned: newPins }

    case ACTION_TYPES.REMOVE_PIN:
      const { [action.recipeId]: pinToRemove, ...leftPin } = state.pinned
      return { ...state, pinned: leftPin }
    default:
      return state
  }
}
