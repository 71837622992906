export enum SeasonScore {
  NoGo = 0,
  Ok = 1,
  Perfect = 2,
}

export type MonthlySeasonScore = [
  SeasonScore,
  SeasonScore,
  SeasonScore,
  SeasonScore,
  SeasonScore,
  SeasonScore,
  SeasonScore,
  SeasonScore,
  SeasonScore,
  SeasonScore,
  SeasonScore,
  SeasonScore
]
