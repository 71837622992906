import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import { useSnackbar } from 'notistack'
import React, { useCallback, useContext, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { findMainIngredientById } from '../../@aligre/lib/mainIngredients'
import { findRecipeById } from '../../@aligre/lib/recipes'
import { isIngredientLineInPantry } from '../../@aligre/lib/users'
import {
  filterUndefined,
  isIngredientLineWithMainIngredient,
  MainIngredient,
} from '../../@aligre/types'
import ListItemLinkInternal from '../../components/ListItemLinkInternal'
import MainIngredientListItem from '../../components/MainIngredientListItem'
import { batchAddToPantry } from '../../services/pantry'
import { RecipeContext } from '../../state/recipes/RecipeProvider'
import { SearchContext } from '../../state/search/SearchProvider'
import { UserContext } from '../../state/user/UserProvider'
import { userActions } from '../../state/user/userReducer'

interface GroceryListContainerProps extends RouteComponentProps {}
export default function GroceryListContainer(props: GroceryListContainerProps) {
  const { groceryListItems, pantry, userDispatch } = useContext(UserContext)
  const { allRecipes, allMainIngredients } = useContext(RecipeContext)
  const { selectedMonth } = useContext(SearchContext)

  const [selectedDict, setSelectedDict] = useState<{ [key: string]: boolean }>(
    {}
  )
  const { enqueueSnackbar } = useSnackbar()

  const onSaveMemo = useCallback(async () => {
    const mainIngredientSelectedIds = Object.keys(selectedDict).filter(
      (k) => selectedDict[k]
    )
    setSelectedDict({})

    userDispatch(userActions.batchAddToPantry(mainIngredientSelectedIds))
    try {
      // @todo use data from dispatch
      await batchAddToPantry(mainIngredientSelectedIds)
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    }
  }, [selectedDict, enqueueSnackbar, userDispatch])

  const onIngredientSelectMemo = useCallback(
    (ingredient: MainIngredient, selected: boolean) => {
      setSelectedDict((selectedDict) => ({
        ...selectedDict,
        [ingredient._id]: selected,
      }))
    },
    [setSelectedDict]
  )

  const recipes = filterUndefined(
    Object.keys(groceryListItems).map((recipeId) =>
      findRecipeById(recipeId, allRecipes)
    )
  )

  const onIngredientClick = useCallback(
    (mainIngredient) => {
      props.history.push(`/ingredient/${mainIngredient._id}`)
    },
    [props.history]
  )

  return (
    <Grid>
      <Grid item xs={12}>
        <List>
          {recipes.map(({ _id, name, ingredients }) => (
            <div key={_id}>
              <ListItemLinkInternal to={`/recipe/${_id}`}>
                <Typography variant='h6'>{name}</Typography>
              </ListItemLinkInternal>
              {ingredients.map((ingredient) => {
                if (
                  isIngredientLineWithMainIngredient(ingredient) &&
                  !isIngredientLineInPantry(ingredient, pantry)
                ) {
                  const mainIngredient = findMainIngredientById(
                    ingredient.mainIngredient,
                    allMainIngredients
                  )
                  return mainIngredient ? (
                    <MainIngredientListItem
                      month={selectedMonth}
                      selected={selectedDict[mainIngredient._id]}
                      key={mainIngredient._id}
                      ingredient={mainIngredient}
                      onIngredientClick={onIngredientClick}
                      onIngredientSelect={onIngredientSelectMemo}
                    />
                  ) : null
                }
                return null
              })}
            </div>
          ))}
        </List>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={onSaveMemo} variant='contained' color='secondary'>
          Transfer to Pantry
        </Button>
      </Grid>
    </Grid>
  )
}
