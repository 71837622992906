import {
  IngredientLineWithMainIngredient,
  SeparatorLine,
} from './ingredientLines'
import { MonthlySeasonScore } from './season'

export type Recipe = {
  readonly _id: string
  name: string
  photo?: string
  source: string
  servings: string
  seasonality: MonthlySeasonScore
  categories: string[]
  directions: string
  ingredients: (SeparatorLine | IngredientLineWithMainIngredient)[]
  cookTime?: number
  prepTime?: number
  sourceUrl?: string
}

export type UnsavedRecipe = Omit<Recipe, '_id' | 'seasonality'>

/**
 * Is recipe not saved yet in database (aka new)?
 * @category recipe
 */
export function isUnsavedRecipe(
  recipe: Recipe | UnsavedRecipe
): recipe is UnsavedRecipe {
  return (recipe as Recipe)._id === undefined
}
