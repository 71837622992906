import Grid from '@material-ui/core/Grid'
import DescriptionIcon from '@material-ui/icons/Description'
import PlusOne from '@material-ui/icons/PlusOne'
import React, { useCallback, useContext } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import AbsoluteFab from '../../components/AbsoluteFab'
import MainIngredientListByAisle from '../../components/MainIngredientListByAisle'
import { RecipeContext } from '../../state/recipes/RecipeProvider'
import { SearchContext } from '../../state/search/SearchProvider'
interface MainIngredientsPageContainerProps extends RouteComponentProps {}

export default function MainIngredientsPageContainer(
  props: MainIngredientsPageContainerProps
) {
  const { allMainIngredients } = useContext(RecipeContext)
  const { selectedMonth } = useContext(SearchContext)

  const onIngredientClickMemo = useCallback(
    (mainIngredient) => {
      props.history.push(`/admin/ingredient/edit/${mainIngredient._id}`)
    },
    [props.history]
  )

  const onIconClickMemo = useCallback(
    (mainIngredient) => {
      props.history.push(`/ingredient/${mainIngredient._id}`)
    },
    [props.history]
  )

  const onAbsoluteFabClickMemo = useCallback(() => {
    props.history.push('/admin/ingredient/create')
  }, [props.history])

  return (
    <>
      <AbsoluteFab adminOnly={true} onClick={onAbsoluteFabClickMemo}>
        <PlusOne />
      </AbsoluteFab>
      <Grid container>
        <Grid item xs={12}>
          <MainIngredientListByAisle
            month={selectedMonth}
            mainIngredients={Object.values(allMainIngredients)}
            onIngredientClick={onIngredientClickMemo}
            onIconClick={onIconClickMemo}
            icon={<DescriptionIcon />}
          />
        </Grid>
      </Grid>
    </>
  )
}
