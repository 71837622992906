import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { MonthlySeasonScore, SeasonScore } from '../@aligre/types'
import seasonScoreColors from '../constants/colors'

export const months: string[] = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
]

const commonStyles = {
  padding: '10px',
  margin: '1px',
  color: 'white',
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 0',
  },
  seasonScore0: {
    ...commonStyles,
    backgroundColor: seasonScoreColors[0],
    paddingTop: '0px',
  },
  seasonScore1: {
    ...commonStyles,
    backgroundColor: seasonScoreColors[1],
    paddingTop: '5px',
  },
  seasonScore2: {
    ...commonStyles,
    backgroundColor: seasonScoreColors[2],
  },
}))

interface SeasonTableProps {
  seasonality: MonthlySeasonScore
}

export default function SeasonTable(props: SeasonTableProps) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {props.seasonality.map((s: SeasonScore, index: number) => {
        const classesArr = ['seasonScore0', 'seasonScore1', 'seasonScore2']
        return (
          //@ts-ignore
          <span key={index} className={classes[classesArr[s]]}>
            {months[index][0].toLowerCase()}
          </span>
        )
      })}
    </div>
  )
}
