import {
  AisleDict,
  isIngredientLineWithMainIngredient,
  MainIngredient,
  MainIngredientDict,
  Recipe,
} from '../../types'
import { getAisleName } from '../aisles'

/**
 * Get the main ingredient from an id if any
 * @category mainIngredient
 */
export const findMainIngredientById = (
  mainIngredientId: string,
  allMainIngredients: MainIngredientDict
): MainIngredient | undefined => allMainIngredients[mainIngredientId]

/**
 * Get the main ingredients from ids
 * @category mainIngredient
 */
export const findMainIngredientsById = (
  mainIngredientIds: string[],
  allMainIngredients: MainIngredientDict
): (MainIngredient | undefined)[] =>
  mainIngredientIds.map((id) => allMainIngredients[id])

/**
 * Get the aisle name for a given main ingredient
 * @category mainIngredient
 */
export const getMainIngredientAisleName = (
  mainIngredient: MainIngredient,
  aisles: AisleDict
): string | string => getAisleName(mainIngredient.aisle, aisles)

/**
 * @internal
 */
const isMainIngredientUsedByRecipe = (
  mainIngredientId: string,
  recipe: Recipe
): boolean => {
  return recipe.ingredients
    .filter(isIngredientLineWithMainIngredient)
    .some((i) => i.mainIngredient === mainIngredientId)
}

/**
 * Get all recipes using a given main ingredient
 * @category mainIngredient
 */
export const getMainIngredientParentRecipes = (
  mainIngredientId: string,
  recipes: Recipe[]
): Recipe[] => {
  return recipes.filter((recipe) =>
    isMainIngredientUsedByRecipe(mainIngredientId, recipe)
  )
}
