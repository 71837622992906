import Grid from '@material-ui/core/Grid'
import { useSnackbar } from 'notistack'
import React, { useCallback, useContext, useMemo } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { findMainIngredientsById } from '../../@aligre/lib/mainIngredients'
import { filterUndefined, MainIngredient } from '../../@aligre/types'
import { addToPantry, removeFromPantry } from '../../services/pantry'
import { RecipeContext } from '../../state/recipes/RecipeProvider'
import { UserContext } from '../../state/user/UserProvider'
import { userActions } from '../../state/user/userReducer'
import PantryList from './components/PantryList'

interface PantryPageContainerProps extends RouteComponentProps {}

export default function PantryPageContainer(props: PantryPageContainerProps) {
  const { allMainIngredients, aisles } = useContext(RecipeContext)
  const { pantry, userDispatch } = useContext(UserContext)
  const { enqueueSnackbar } = useSnackbar()

  const pantryMainIngredients = useMemo(
    () =>
      filterUndefined(
        findMainIngredientsById(Object.keys(pantry), allMainIngredients)
      ),
    [allMainIngredients, pantry]
  )

  // Problem: the method is recreated every single time the pantry changes
  // We should rather use addPantryIngredient for the store and the backend
  // We could also avoid changing the state and use the backend payload to update the application
  // @todo avoid rerendering in PantryListItem
  const onPantryIngredientAdd = useCallback(
    async (pantryIngredient: MainIngredient) => {
      try {
        const { mainIngredient } = await addToPantry(pantryIngredient._id)
        userDispatch(userActions.addToPantry(mainIngredient))
      } catch (e) {
        enqueueSnackbar(e.message, { variant: 'error' })
      }
    },
    [userDispatch, enqueueSnackbar]
  )

  const onPantryIngredientRemove = useCallback(
    async (pantryIngredient: MainIngredient) => {
      try {
        await removeFromPantry(pantryIngredient._id)
        userDispatch(userActions.removeFromPantry(pantryIngredient._id))
      } catch (e) {
        enqueueSnackbar(e.message, { variant: 'error' })
      }
    },
    [enqueueSnackbar, userDispatch]
  )

  const onIngredientClick = useCallback(
    (mainIngredient) => {
      props.history.push(`/ingredient/${mainIngredient._id}`)
    },
    [props.history]
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        <PantryList
          aisles={aisles}
          mainIngredients={allMainIngredients}
          pantryIngredients={pantryMainIngredients}
          onAdd={onPantryIngredientAdd}
          onRemove={onPantryIngredientRemove}
          onIngredientClick={onIngredientClick}
        />
      </Grid>
    </Grid>
  )
}
