import {
  DeleteResponse,
  PinRecipeResponse,
  RecipeIdRequestBody,
} from '../@aligre/types/api'
import apiFetch from './apiFetch'

export async function pinRecipe(recipeId: string) {
  return await apiFetch<RecipeIdRequestBody, PinRecipeResponse>(
    'me/pinned',
    'POST',
    {
      recipeId,
    }
  )
}

export async function unpinRecipe(recipeId: string) {
  return await apiFetch<RecipeIdRequestBody, DeleteResponse>(
    'me/pinned',
    'DELETE',
    {
      recipeId,
    }
  )
}
