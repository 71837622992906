import {
  isIngredientLineWithMainIngredient,
  Recipe,
} from '../../../@aligre/types'

const getMainIngredientPrepForRecipe = (
  r: Recipe,
  mainIngredient: string
): string => {
  const m = r.ingredients
    .filter(isIngredientLineWithMainIngredient)
    .find((i) => i.mainIngredient === mainIngredient)
  return m ? m.prep : ''
}

export default getMainIngredientPrepForRecipe
