import { RatingScore, Recipe } from '../@aligre/types'
import {
  DeleteResponse,
  RecipeIdRequestBody,
  UpdateRecipeRatingRequestBody,
  UpdateRecipeRatingResponse,
} from '../@aligre/types/api'
import apiFetch from './apiFetch'

export async function deleteRating(recipe: Recipe) {
  return await apiFetch<RecipeIdRequestBody, DeleteResponse>(
    'me/rating',
    'DELETE',
    { recipeId: recipe._id }
  )
}

export async function updateRating(rating: RatingScore, recipe: Recipe) {
  return await apiFetch<
    UpdateRecipeRatingRequestBody,
    UpdateRecipeRatingResponse
  >('me/rating', 'PUT', {
    rating,
    recipeId: recipe._id,
  })
}
