import { getRecipeMonthSeasonScore } from '../../../@aligre/lib/seasonality/season'
import {
  isIngredientLineWithMainIngredient,
  Recipe,
  RecipeRatingDict,
  SeasonScore,
  UserPantryDict,
} from '../../../@aligre/types'
import deburrAndLowerCase from '../../../lib/deburrAndLowerCase'

const commonIngredients = (
  ingredients: string[],
  recipeIngredients: string[]
): string[] => {
  return ingredients.reduce((acc: string[], ingredient) => {
    if (recipeIngredients.includes(ingredient)) {
      acc = [...acc, ingredient]
    }
    return acc
  }, [])
}

interface SearchCriteria {
  recipeName?: string
  selectedMainIngredients: string[]
  selectedCategories: string[]
  selectedSources: string[]
  minimumIngredientsInCommon: number
  selectedMonth: number
  minimumSeasonScore: SeasonScore
  neverRatedOnly: boolean
  missingPictureOnly: boolean
}

export default function searchRecipes(
  recipes: Recipe[],
  searchParams: SearchCriteria,
  pantry: UserPantryDict,
  ratings: RecipeRatingDict
) {
  const { selectedMainIngredients } = searchParams
  const pantryIngredientsNotSelected = Object.keys(pantry).filter(
    (p) => !selectedMainIngredients.includes(p)
  )

  const filteredRecipes = recipes
    .filter((r) => {
      return searchParams.recipeName && searchParams.recipeName
        ? deburrAndLowerCase(r.name).includes(
            deburrAndLowerCase(searchParams.recipeName)
          )
        : true
    })
    .filter((r) => {
      const seasonScore = getRecipeMonthSeasonScore(
        r,
        searchParams.selectedMonth
      )
      return seasonScore === undefined
        ? true
        : seasonScore >= searchParams.minimumSeasonScore
    })
    .filter((r) => {
      return searchParams.neverRatedOnly ? !Boolean(ratings[r._id]) : true
    })
    .filter((r) => {
      return searchParams.missingPictureOnly ? r?.photo === undefined  : true
    })
    .filter((r) => {
      return searchParams.selectedSources.length
        ? searchParams.selectedSources.includes(r.source)
        : true
    })
    .filter((r) =>
      searchParams.selectedCategories.filter((c) => c !== '').length
        ? r.categories.some((c) => {
            return searchParams.selectedCategories.includes(c)
          })
        : true
    )

  const recipesMap = new Map<number, Recipe[]>()

  const minInCommon = Math.min(
    searchParams.selectedMainIngredients.length,
    searchParams.minimumIngredientsInCommon
  )
  let maxInCommon = minInCommon
  filteredRecipes.forEach((r) => {
    const recipeIngredients = r.ingredients
      .filter(isIngredientLineWithMainIngredient)
      .map((i) => i.mainIngredient)
    const inCommonSelected = commonIngredients(
      selectedMainIngredients,
      recipeIngredients
    ).length
    const inCommonPantry = commonIngredients(
      pantryIngredientsNotSelected,
      recipeIngredients
    ).length
    const inCommon = inCommonSelected + inCommonPantry
    if (
      (inCommonSelected > 0 || selectedMainIngredients.length === 0) &&
      inCommon >= minInCommon
    ) {
      if (maxInCommon < inCommon) maxInCommon = inCommon
      recipesMap.set(inCommon, [...(recipesMap.get(inCommon) || []), r])
    }
  })

  let results: Recipe[] = []
  for (let i = maxInCommon; i >= minInCommon; i--) {
    if (recipesMap.has(i)) {
      results = [...results, ...recipesMap.get(i)]
    }
  }

  return results
}
