/**
 * @private
 */
export const SIZE_NOT_COMMUNICATED = 'N/C'

/**
 * @private
 */
export enum Sizes {
  S = 'S',
  M = 'M',
  L = 'L',
}

/**
 * @internal
 */
const sizesMap: { [key in Sizes]: string[] } = {
  [Sizes.L]: [
    'grosses',
    'grosse',
    'gros',
    'bonnes',
    'bonne',
    'bons',
    'bon',
    'belles',
    'belle',
    'beaux',
    'beau',
    'grandes',
    'grande',
    'grands',
    'grand',
  ],
  [Sizes.M]: ['de taille moyenne', 'moyennes', 'moyenne', 'moyens', 'moyen'],
  [Sizes.S]: ['petites', 'petite', 'petits', 'petit'],
}

/**
 * @private
 */
export const sizeWords = Object.values(sizesMap).reduce(
  (acc: string[], s: string[]): string[] => [...acc, ...s],
  []
)

/**
 * @private
 */
export const getSize = (size: string): string => {
  let match: string = SIZE_NOT_COMMUNICATED

  Object.keys(sizesMap).forEach((sizeCategory) => {
    const sizeArr = sizesMap[sizeCategory as Sizes]
    if (sizeArr.includes(size)) {
      match = sizeCategory
    }
  })
  return match
}
