import diceCoefficientCompare from 'natural/lib/natural/distance/dice_coefficient'
import { MainIngredientDict } from '../../../@aligre/types'
import { sortMapByValues } from '../../../lib/dataSorting'

const scoreThreshold: number = 0.6

export default function getSimilarMainIngredients(
  ingredient: string,
  allMainIngredients: MainIngredientDict
): string[] {
  if (ingredient === '') return []
  const map = new Map<string, number>()
  Object.values(allMainIngredients).forEach(({ name, _id }) => {
    const diceCoefficient = diceCoefficientCompare(name, ingredient)
    if (diceCoefficient > scoreThreshold) {
      map.set(_id, diceCoefficient as number)
    }
  })
  const mapSortByValues = sortMapByValues(map)
  return mapSortByValues.size ? Array.from(mapSortByValues.keys()) : []
}
