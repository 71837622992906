import Edit from '@material-ui/icons/Edit'
import React, { useCallback, useContext } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import AbsoluteFab from '../../components/AbsoluteFab'
import { RecipeContext } from '../../state/recipes/RecipeProvider'
import IngredientPage from './IngredientPage'

interface IngredientPageContainerProps
  extends RouteComponentProps<{
    _id: string
  }> {}

export default function IngredientPageContainer(
  props: IngredientPageContainerProps
) {
  const { _id } = props.match.params
  const { allMainIngredients } = useContext(RecipeContext)
  const ingredient = allMainIngredients[_id]
  const onRecipeClickMemo = useCallback(
    (recipe) => {
      props.history.push(`/recipe/${recipe._id}`)
    },
    [props.history]
  )
  const onIngredientClickMemo = useCallback(
    (ingredient) => {
      props.history.push(`/ingredient/${ingredient._id}`)
    },
    [props.history]
  )

  const onAbsoluteFabClickMemo = useCallback(() => {
    props.history.push(`/admin/ingredient/edit/${_id}`)
  }, [props.history, _id])

  if (!ingredient) {
    return <Redirect to='/404' />
  }

  if (ingredient.recipe) {
    props.history.replace(`/recipe/${ingredient.recipe}`)
  }

  return (
    <>
      <AbsoluteFab adminOnly={true} onClick={onAbsoluteFabClickMemo}>
        <Edit />
      </AbsoluteFab>
      <IngredientPage
        mainIngredient={ingredient}
        onRecipeClick={onRecipeClickMemo}
        onIngredientClick={onIngredientClickMemo}
      />
    </>
  )
}
