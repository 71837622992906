import {
  AddToPantryResponse,
  BatchAddToPantryResponse,
  DeleteResponse,
  MainIngredientIdRequestBody,
  MainIngredientIdsRequestBody,
} from '../@aligre/types/api'
import apiFetch from './apiFetch'

export async function addToPantry(mainIngredientId: string) {
  return await apiFetch<MainIngredientIdRequestBody, AddToPantryResponse>(
    'me/pantry',
    'POST',
    { mainIngredientId }
  )
}
export async function batchAddToPantry(mainIngredientIds: string[]) {
  return await apiFetch<MainIngredientIdsRequestBody, BatchAddToPantryResponse>(
    'me/pantry/batch',
    'POST',
    { mainIngredientIds }
  )
}

export async function removeFromPantry(mainIngredientId: string) {
  return await apiFetch<MainIngredientIdRequestBody, DeleteResponse>(
    'me/pantry',
    'DELETE',
    { mainIngredientId }
  )
}
