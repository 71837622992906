import { ListItem } from '@material-ui/core'
import React from 'react'
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom'

const RouterLinkForwardRef = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => <ReactRouterLink innerRef={ref as any} {...props} />
)

export default function ListItemLink(props: any) {
  return <ListItem button component={RouterLinkForwardRef} {...props} />
}
