import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import React, { useContext } from 'react'
import { createSelectOptions } from '../lib/selectOptions'
import { RecipeContext } from '../state/recipes/RecipeProvider'

interface CategoryFormControlSelectProps {
  selected: string[]
  onChange: (selected: string[]) => void
}

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}))

export default function CategoryFormControlSelect(
  props: CategoryFormControlSelectProps
) {
  const classes = useStyles()
  const { allCategories } = useContext(RecipeContext)
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor='category'>Category</InputLabel>
      <Select
        multiple
        input={<Input id='select-category' />}
        value={props.selected}
        onChange={(event) => {
          const selected = event.target.value as string[]
          props.onChange(selected)
        }}
        renderValue={(selected) => {
          return (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip
                  size='small'
                  key={value}
                  label={allCategories[value]}
                  className={classes.chip}
                />
              ))}
            </div>
          )
        }}>
        {createSelectOptions(allCategories).map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
