import List from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { isRecipePinned } from '../@aligre/lib/recipes'
import { Recipe, UserPinDict } from '../@aligre/types'
import useWhyDidIUpdate from '../hooks/useWhyDidIUpdate'
import RecipeListItem from './RecipeListItem'

interface RecipeListProps {
  scrollableHeight?: number
  recipes: Recipe[]
  onRecipeClick: (recipe: Recipe) => void
  getSecondaryText: (recipe: Recipe) => string
  pinned?: UserPinDict
}

const useStyles = makeStyles((theme) => ({
  scrollableList: (props: RecipeListProps) =>
    props.scrollableHeight && Number.isInteger(props.scrollableHeight)
      ? {
          height: props.scrollableHeight,
          overflow: 'scroll',
        }
      : {},
}))

export default function RecipeList(props: RecipeListProps) {
  const classes = useStyles(props)
  console.count('RecipeList')
  useWhyDidIUpdate('RecipeList', props)

  return (
    <List className={classes.scrollableList}>
      {props.recipes.map((recipe: Recipe) => (
        <RecipeListItem
          isPinned={props.pinned ? isRecipePinned(recipe, props.pinned) : false}
          onRecipeClick={props.onRecipeClick}
          getSecondaryText={props.getSecondaryText}
          recipe={recipe}
          key={recipe._id}
        />
      ))}
    </List>
  )
}
