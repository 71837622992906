/**
 * @private
 */
export const PIECE_UNITY = 'piece'
/**
 * @private
 */
export const UNITY_NOT_COMMUNICATED = 'N/C'

/**
 * @private
 */
export const unityWithoutDe = [
  'g',
  'kg',
  'T.',
  't.',
  'C.',
  'l',
  'cl',
  'ml',
  'cm',
]

/**
 * @private
 */
export const acceptedUnitySingularRe = [
  'bouteille',
  'gousse',
  'brin',
  'pincée',
  'poignée',
  'morceau',
  'feuille',
  'filet',
  'bouquet',
  'portion',
  'tranche',
  'branche',
  'cube',
  'sachet',
  'goutte',
  'g',
  'kg',
  'T.',
  't.',
  'C.',
  'l',
  'cl',
  'ml',
  'cm',
]

// @todo unify these arrays
// @todo why filet is singular only? filet d'huile maybe?

/**
 * @private
 */
export const acceptedUnityRe = [
  'bouteilles?',
  'gousses?',
  'brins?',
  'pincées?',
  'poignées?',
  'morceaux?',
  'feuilles?',
  'filet', // singulier pour filet d'huile exclusivement @todo: improve
  'bouquets?',
  'portions?',
  'tranches?',
  'branches?',
  'cubes?',
  'sachets?',
  'gouttes?',
  'g',
  'kg',
  'T.',
  't.',
  'C.',
  'l',
  'cl',
  'ml',
  'cm',
]

/**
 * @private
 * @category ingredientLine
 */
export const singularizeUnity = (u: string) => u.trim().replace(/(s|x)$/, '')

/**
 * @private
 * @category ingredientLine
 */
export const pluralizeUnity = (u: string) => {
  if (unityWithoutDe.includes(u)) return u
  if (['s', 'x'].includes(u[u.length - 1])) return u
  if (u === 'morceau') return 'morceaux'
  return `${u}s`
}

/**
 * @private
 * @category ingredientLine
 */
export const addDe = (u: string, ingredient: string) => {
  if (unityWithoutDe.includes(u) || u.length === 0) return ''
  return ['a', 'e', 'i', 'o', 'u', 'y', 'h'].includes(
    ingredient[0].toLowerCase()
  )
    ? "d'"
    : 'de '
}

/**
 * Is Unity valid?
 * @param unity
 * @category ingredientLine
 */
export const isUnityValid = (unity: string): boolean => {
  const regExpWithUnity = new RegExp(
    `^${[...acceptedUnitySingularRe, PIECE_UNITY, UNITY_NOT_COMMUNICATED].join(
      '|'
    )}$`
  )
  return regExpWithUnity.test(unity)
}
