import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import React, { useCallback, useContext } from 'react'
import { createSelectOptions } from '../lib/selectOptions'
import { RecipeContext } from '../state/recipes/RecipeProvider'

interface SourceFormControlSelectProps {
  selected: string[]
  multiple: boolean
  onChange: (selected: string[] | string) => void
}

export default function SourceFormControlSelect(
  props: SourceFormControlSelectProps
) {
  const { allSources } = useContext(RecipeContext)
  const { onChange } = props

  const onChangeMemo = useCallback(
    (event) => {
      onChange(event.target.value)
    },
    [onChange]
  )

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor='select-source'>Source</InputLabel>
      <Select
        inputProps={{
          id: 'select-source',
        }}
        multiple={props.multiple}
        value={props.selected}
        onChange={onChangeMemo}>
        {createSelectOptions(allSources).map(({ value, label }) => {
          return (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
