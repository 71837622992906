import { useMemo, useReducer, useState } from 'react'
import isIngredientLineValid from '../../../@aligre/lib/ingredientLines/isValid'
import { computeMonthlySeasonScore } from '../../../@aligre/lib/seasonality/season'
import {
  MainIngredientDict,
  Recipe,
  UnsavedRecipe,
} from '../../../@aligre/types'
import ingredientLineReducer, {
  ingredientLineActions,
} from './ingredientLineReducer'

const useRecipeForm = (
  initialState: Recipe | UnsavedRecipe,
  recipes: Recipe[],
  allMainIngredients: MainIngredientDict,
  onSubmit: (recipe: Recipe | UnsavedRecipe) => void
) => {
  const { ingredients: initialIngredients, ...otherFields } = initialState
  const [fields, setFields] = useState(otherFields)
  const [ingredients, dispatch] = useReducer(
    ingredientLineReducer,
    initialIngredients
  )

  const seasonScore = useMemo(
    () => computeMonthlySeasonScore(ingredients, recipes, allMainIngredients),
    [ingredients, recipes, allMainIngredients]
  )

  function handleFieldChange<K extends keyof Recipe>(
    fieldName: K,
    value: Recipe[K]
  ) {
    if (!['_id', 'photos', 'ingredients', 'seasonality'].includes(fieldName)) {
      setFields((fields) => ({ ...fields, [fieldName]: value }))
    }
  }

  function setIngredientsFromIngredientLines(ingredientLines: string[]) {
    dispatch(ingredientLineActions.set(ingredientLines))
  }

  function handleIngredientLineChange(line: string, index: number) {
    dispatch(ingredientLineActions.update(index, line))
  }

  function handleRemoveLine(index: number) {
    dispatch(ingredientLineActions.remove(index))
  }

  function handleMainIngredientChange(
    mainIngredient: string,
    index: number,
    changeType: 'select' | 'unselect'
  ) {
    if (changeType === 'select') {
      dispatch(ingredientLineActions.setMainIngredient(index, mainIngredient))
    } else {
      dispatch(ingredientLineActions.unsetMainIngredient(index))
    }
  }

  const formIsValid = useMemo(() => {
    const filteredIngredients = ingredients.filter(isIngredientLineValid)
    return (
      Boolean(ingredients.length) &&
      filteredIngredients.length === ingredients.length
    )
  }, [ingredients])

  async function handleSubmit() {
    if (formIsValid) {
      const recipe: Recipe | UnsavedRecipe = {
        ...fields,
        ingredients: ingredients.filter(isIngredientLineValid),
        seasonality: seasonScore,
      }
      onSubmit(recipe)
    } else {
      console.error('Recipe is invalid, some ingredients are invalid.')
    }
  }

  function diff() {
    const originalRecipe = {
      ...otherFields,
      ingredients: initialIngredients,

      ...(otherFields.hasOwnProperty('seasonality') && {
        // @ts-ignore
        seasonality: otherFields.seasonality,
      }),
    }

    const recipe = {
      ...fields,
      ingredients,
      seasonality: seasonScore,
    }
    return { newCode: recipe, oldCode: originalRecipe }
  }

  return {
    handleFieldChange,
    fields,
    ingredients,
    handleSubmit,
    setIngredientsFromIngredientLines,
    handleIngredientLineChange,
    handleMainIngredientChange,
    handleRemoveLine,
    seasonScore,
    formIsValid,
    diff,
  }
}
export default useRecipeForm
