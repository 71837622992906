import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React, { useContext } from 'react'
import { getAllVerbsAndPreps } from '../../@aligre/lib/ingredientLines/getAllVerbsAndPreps'
import { RecipeContext } from '../../state/recipes/RecipeProvider'

export default function Stats() {
  const { allRecipes } = useContext(RecipeContext)
  const { allVerbs, allPreps } = getAllVerbsAndPreps(allRecipes)

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Typography variant='h4'>All Verbs</Typography>
        {allVerbs.map((verb) => (
          <Typography key={verb} variant='body1'>
            {verb}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant='h4'>All Preparation Modes</Typography>
        {allPreps.map((prepMode) => (
          <Typography key={prepMode} variant='body1'>
            {prepMode}
          </Typography>
        ))}
      </Grid>
    </Grid>
  )
}
