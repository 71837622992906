import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Delete from '@material-ui/icons/Delete'
import React, { useContext, useMemo, useState } from 'react'
import { buildIngredientLine } from '../../../@aligre/lib/ingredientLines/buildIngredientLine'
import isIngredientLineValid from '../../../@aligre/lib/ingredientLines/isValid'
import { findMainIngredientById } from '../../../@aligre/lib/mainIngredients'
import {
  IngredientLineWithMainIngredient,
  IngredientLineWithoutMainIngredient,
  isIngredientLineWithMainIngredient,
  MainIngredient,
} from '../../../@aligre/types'
import { RecipeContext } from '../../../state/recipes/RecipeProvider'
import getSimilarMainIngredients from '../lib/getSimilarMainIngredients'

interface PartialIngredientFormFieldProps {
  ingredient:
    | IngredientLineWithoutMainIngredient
    | IngredientLineWithMainIngredient
  guesses: boolean
  index: number
  onChange: (line: string, index: number) => void
  onMainIngredientChange: (
    mainIngredient: string,
    index: number,
    changeType: 'select' | 'unselect'
  ) => void
  onRemove: (index: number) => void
}

const useStyles = makeStyles((theme) => ({
  margin: {
    marginBottom: theme.spacing(2),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}))

function PartialIngredientFormField(props: PartialIngredientFormFieldProps) {
  const classes = useStyles()
  const {
    ingredient,
    index,
    guesses,
    onChange,
    onMainIngredientChange,
    onRemove,
  } = props
  const { allMainIngredients } = useContext(RecipeContext)
  const simplifiedLine = buildIngredientLine(ingredient)
  const [line, setLine] = useState(simplifiedLine)

  const mainIngredientGuesses = useMemo(() => {
    if (guesses) {
      const guessesSet = new Set<string>([
        ...getSimilarMainIngredients(ingredient.ingredient, allMainIngredients),
        ...(isIngredientLineWithMainIngredient(ingredient)
          ? [ingredient.mainIngredient]
          : []),
      ])
      return Array.from(guessesSet)
    }
    return []
  }, [guesses, ingredient, allMainIngredients])

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} className={classes.margin}>
        <TextField
          fullWidth
          label='Ingredient Line'
          value={line}
          error={!isIngredientLineValid(ingredient)}
          onChange={(e) => {
            const value = e.currentTarget.value
            setLine(value)
            onChange(value, index)
          }}
        />
      </Grid>
      <Grid item xs={10} className={classes.margin}>
        <TextField
          fullWidth
          label='Simplified Line'
          value={simplifiedLine}
          disabled
        />
      </Grid>
      <Grid item xs={2}>
        <IconButton
          aria-label='Delete Ingredient'
          onClick={() => onRemove(index)}>
          <Delete />
        </IconButton>
      </Grid>

      <Grid item xs={12} className={classes.margin}>
        <TextField label='Quantity' value={ingredient.quantity} disabled />
        <TextField label='Unity' value={ingredient.unity} disabled />
        <TextField label='Ingredient' value={ingredient.ingredient} disabled />
        <TextField label='Prep' value={ingredient.prep} disabled />
      </Grid>

      <Grid item xs={12} className={classes.margin}>
        {guesses &&
          mainIngredientGuesses.map((mainIngredientGuess) => {
            const isSelected =
              isIngredientLineWithMainIngredient(ingredient) &&
              mainIngredientGuess === ingredient.mainIngredient
            return (
              <Chip
                className={classes.chip}
                color={isSelected ? 'primary' : 'default'}
                key={mainIngredientGuess}
                label={
                  (findMainIngredientById(
                    mainIngredientGuess,
                    allMainIngredients
                  ) as MainIngredient).name
                }
                onClick={() => {
                  onMainIngredientChange(
                    mainIngredientGuess,
                    index,
                    isSelected ? 'unselect' : 'select'
                  )
                }}
              />
            )
          })}
      </Grid>
      <Grid item xs={12} className={classes.margin}>
        <Divider />
      </Grid>
    </Grid>
  )
}

PartialIngredientFormField.defaultProps = {
  guesses: true,
} as Partial<PartialIngredientFormFieldProps>

function isIdentical(
  prevProps: PartialIngredientFormFieldProps,
  nextProps: PartialIngredientFormFieldProps
) {
  const isSameMainIngredient =
    isIngredientLineWithMainIngredient(prevProps.ingredient) &&
    isIngredientLineWithMainIngredient(nextProps.ingredient)
      ? prevProps.ingredient.mainIngredient ===
        nextProps.ingredient.mainIngredient
      : true

  const isIdentical =
    prevProps.ingredient.quantity === nextProps.ingredient.quantity &&
    prevProps.ingredient.unity === nextProps.ingredient.unity &&
    prevProps.ingredient.prep === nextProps.ingredient.prep &&
    prevProps.ingredient.ingredient === nextProps.ingredient.ingredient &&
    isIngredientLineWithMainIngredient(prevProps.ingredient) ===
      isIngredientLineWithMainIngredient(nextProps.ingredient) &&
    isSameMainIngredient

  return isIdentical
}
export default React.memo(PartialIngredientFormField, isIdentical)
