import { isIngredientLineWithMainIngredient, Recipe } from '../../types'
import { PREP_NOT_COMMUNICATED } from './utils/prep'
import {
  getFirst3rdVerbOccurrence,
  getFirstVerbOccurrence,
  isVerbValid,
  verbToSingular,
} from './utils/verb'

/**
 * Get all prep field values for a given set of recipes and optionaly a given mainIngredient
 * @private
 * @category prep
 */
const getAllPrepValues = (
  recipes: Recipe[],
  mainIngredientId?: string
): string[] => {
  return recipes.reduce((acc: string[], r: Recipe) => {
    const preps = r.ingredients
      .filter(isIngredientLineWithMainIngredient)
      .filter((i) =>
        mainIngredientId ? i.mainIngredient === mainIngredientId : true
      )
      .filter((i) => i.prep && i.prep !== PREP_NOT_COMMUNICATED)
      .map((i) => i.prep)
    return [...acc, ...preps]
  }, [])
}

/**
 * @category prep
 */
export const getAllVerbsAndPreps = (
  recipes: Recipe[],
  mainIngredientId?: string
): { allVerbs: string[]; allPreps: string[] } => {
  const preps = getAllPrepValues(recipes, mainIngredientId)

  const { allVerbs: allVerbsSet, allPreps: allPrepsSet } = preps.reduce(
    (acc, p) => {
      const { allVerbs, allPreps } = acc

      const parts = p.split(', ').reduce((acc: string[], p) => {
        return [...acc, ...p.split(' et ')]
      }, [])

      parts.forEach((p) => {
        const firstVerb = getFirstVerbOccurrence(p)
        const first3rdVerb = getFirst3rdVerbOccurrence(p)

        if (firstVerb) {
          const singularVerb = verbToSingular(firstVerb)

          if (isVerbValid(singularVerb)) {
            allPreps.add(p.replace(firstVerb, singularVerb))
            allVerbs.add(singularVerb)
          } else {
            allPreps.add(p)
          }
        }

        if (first3rdVerb) {
          const singularVerb = verbToSingular(first3rdVerb)
          allPreps.add(p.replace(first3rdVerb, singularVerb))
          allVerbs.add(singularVerb)
        }
      })

      return { allVerbs, allPreps }
    },
    { allVerbs: new Set(), allPreps: new Set() }
  )

  return {
    // @ts-ignore
    allVerbs: [...allVerbsSet].sort((a, b) => a.localeCompare(b)),
    // @ts-ignore
    allPreps: [...allPrepsSet].sort((a, b) => a.localeCompare(b)),
  }
}

/**
 * Get all preps for a given main ingredient Id
 * @category prep
 */
export const getMainIngredientAllPreps = (
  recipes: Recipe[],
  mainIngredientId: string
) => getAllVerbsAndPreps(recipes, mainIngredientId).allPreps
