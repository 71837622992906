import Link from '@material-ui/core/Link'
import React from 'react'
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom'

const RouterLinkForwardRef = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => <ReactRouterLink innerRef={ref as any} {...props} />
)

export default function LinkInternal(props: any) {
  return <Link {...props} component={RouterLinkForwardRef} />
}
