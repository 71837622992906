import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, { useCallback } from 'react'
import {
  isSavedIngredient,
  MainIngredient,
  UnsavedMainIngredient,
} from '../../@aligre/types'
import AisleFormControlSelect from '../../components/AisleFormControlSelect'
import DeleteButton from '../../components/DeleteButton'
import SeasonTable from '../../components/SeasonTable'
import useMainIngredientForm from './hooks/useMainIngredientForm'

interface MainIngredientFormProps {
  mainIngredient: MainIngredient | UnsavedMainIngredient
  onSubmit: (mainIngredient: MainIngredient | UnsavedMainIngredient) => void
  onView: (mainIngredient: MainIngredient) => void
  onDelete: (mainIngredient: MainIngredient) => void
}

const useStyles = makeStyles((theme) => ({
  margin: {
    marginBottom: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1, 2, 0, 0),
  },
}))

export default function MainIngredientForm(props: MainIngredientFormProps) {
  const classes = useStyles()
  const { mainIngredient: initialMainIngredient, onDelete, onView } = props

  const {
    fields,
    handleFieldChange,
    handleSubmit,
    isFormValid,
    isSeasonalityValid,
    getSeasonality,
  } = useMainIngredientForm(initialMainIngredient, props.onSubmit)

  const onDeleteMemo = useCallback(() => {
    isSavedIngredient(initialMainIngredient) && onDelete(initialMainIngredient)
  }, [onDelete, initialMainIngredient])

  const onViewMemo = useCallback(() => {
    isSavedIngredient(initialMainIngredient) && onView(initialMainIngredient)
  }, [onView, initialMainIngredient])

  return (
    <Grid container>
      <Grid item xs={12} className={classes.margin}>
        <TextField
          fullWidth
          label='Name'
          value={fields.name}
          onChange={(event) => {
            handleFieldChange('name', event.currentTarget.value)
          }}
          required
        />
      </Grid>

      <Grid item xs={12} className={classes.margin}>
        <AisleFormControlSelect
          selected={fields.aisle}
          onChange={(selected) => {
            handleFieldChange('aisle', selected)
          }}
        />
      </Grid>

      <Grid item xs={12} className={classes.margin}>
        <TextField
          fullWidth
          helperText='Type an existing recipeId'
          label='Recipe'
          value={fields.recipe}
          onChange={(event) => {
            handleFieldChange('recipe', event.currentTarget.value)
          }}
        />
      </Grid>

      <Grid item xs={12}>
        {isSeasonalityValid(fields.seasonalityStr) && (
          <SeasonTable seasonality={getSeasonality(fields.seasonalityStr)} />
        )}
      </Grid>

      <Grid item xs={12} className={classes.margin}>
        <TextField
          fullWidth
          helperText='Leave empty or fill with 12 numbers: 2,2,2,2,2,2,2,2,2,2,2,2'
          label='Seasonality'
          value={fields.seasonalityStr}
          onChange={(event) => {
            handleFieldChange('seasonalityStr', event.currentTarget.value)
          }}
        />
      </Grid>

      <Grid item xs={12}>
        {props.mainIngredient && (
          <>
            <DeleteButton onAgree={onDeleteMemo} className={classes.submit} />
            <Button
              variant='text'
              color='primary'
              className={classes.submit}
              onClick={onViewMemo}>
              View
            </Button>
          </>
        )}

        <Button
          variant='contained'
          color='primary'
          onClick={handleSubmit}
          className={classes.submit}
          disabled={!isFormValid()}>
          Submit
        </Button>
      </Grid>
    </Grid>
  )
}
