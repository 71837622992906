import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import React, { useContext, useMemo } from 'react'
import { getMainIngredientAisleName } from '../@aligre/lib/mainIngredients'
import { MainIngredient } from '../@aligre/types'
import { RecipeContext } from '../state/recipes/RecipeProvider'
import MainIngredientListItem from './MainIngredientListItem'

interface MainIngredientListByAisleProps {
  mainIngredients: MainIngredient[]
  onIngredientClick: (ingredient: MainIngredient) => void
  onIngredientSelect?: (ingredient: MainIngredient, selected: boolean) => void
  onIconClick?: (ingredient: MainIngredient) => void
  icon?: JSX.Element
  month?: number
  selectedDict?: { [mainIngredientId: string]: boolean }
}

export default function MainIngredientListByAisle(
  props: MainIngredientListByAisleProps
) {
  const { aisles } = useContext(RecipeContext)

  const aislesDict = useMemo(() => {
    return props.mainIngredients.reduce((acc, p) => {
      const aisle = getMainIngredientAisleName(p, aisles)
      acc.set(aisle, [...(acc.get(aisle) || []), p])
      return acc
    }, new Map<string, MainIngredient[]>())
  }, [props.mainIngredients, aisles])

  return (
    <List>
      {[...aislesDict.keys()].sort().map((aisle) => (
        <div key={aisle}>
          <Typography variant='caption'>{aisle}</Typography>
          {(aislesDict.get(aisle) || []).map((mainIngredient) => (
            <MainIngredientListItem
              key={mainIngredient._id}
              ingredient={mainIngredient}
              month={props.month}
              selected={
                props.selectedDict
                  ? props.selectedDict[mainIngredient._id]
                  : undefined
              }
              onIngredientClick={props.onIngredientClick}
              onIngredientSelect={props.onIngredientSelect}
              onIconClick={props.onIconClick}
              icon={props.icon}
            />
          ))}
        </div>
      ))}
    </List>
  )
}
