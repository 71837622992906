import parseIngredientLine from '../../../@aligre/lib/ingredientLines/parseIngredientLine'
import {
  IngredientLineWithMainIngredient,
  IngredientLineWithoutMainIngredient,
  isIngredientLineWithMainIngredient,
  SeparatorLine,
} from '../../../@aligre/types'

enum ACTION_TYPES {
  SET = 'SET_FROM_LINES',
  UPDATE = 'UPDATE_INGREDIENT_LINE',
  REMOVE = 'REMOVE_INGREDIENT_LINE',
  SET_MAIN_INGREDIENT = 'SET_MAIN_INGREDIENT',
  UNSET_MAIN_INGREDIENT = 'UNSET_MAIN_INGREDIENT',
}

function setFromLines(ingredientLines: string[]) {
  return {
    type: ACTION_TYPES.SET as ACTION_TYPES.SET,
    ingredientLines,
  }
}

function updateIngredientLine(index: number, line: string) {
  return {
    type: ACTION_TYPES.UPDATE as ACTION_TYPES.UPDATE,
    index,
    line,
  }
}

function removeIngredientLine(index: number) {
  return {
    type: ACTION_TYPES.REMOVE as ACTION_TYPES.REMOVE,
    index,
  }
}

function setMainIngredient(index: number, mainIngredient: string) {
  return {
    type: ACTION_TYPES.SET_MAIN_INGREDIENT as ACTION_TYPES.SET_MAIN_INGREDIENT,
    index,
    mainIngredient,
  }
}

function unsetMainIngredient(index: number) {
  return {
    type: ACTION_TYPES.UNSET_MAIN_INGREDIENT as ACTION_TYPES.UNSET_MAIN_INGREDIENT,
    index,
  }
}

type IngredientsAction =
  | ReturnType<typeof setFromLines>
  | ReturnType<typeof updateIngredientLine>
  | ReturnType<typeof removeIngredientLine>
  | ReturnType<typeof setMainIngredient>
  | ReturnType<typeof unsetMainIngredient>

export const ingredientLineActions = {
  set: setFromLines,
  update: updateIngredientLine,
  remove: removeIngredientLine,
  setMainIngredient,
  unsetMainIngredient,
}

type IngredientLineState = (
  | SeparatorLine
  | IngredientLineWithMainIngredient
  | IngredientLineWithoutMainIngredient
)[]

const defaultState = [] as IngredientLineState

export default function reducer(
  state: IngredientLineState = defaultState,
  action: IngredientsAction
): IngredientLineState {
  switch (action.type) {
    case ACTION_TYPES.SET:
      return [
        ...state,
        ...action.ingredientLines.map((ingredientLine: string) =>
          parseIngredientLine(ingredientLine)
        ),
      ]

    case ACTION_TYPES.UPDATE:
      if (action.index >= 0 && action.index < state.length) {
        return [
          ...state.slice(0, action.index),
          { ...state[action.index], ...parseIngredientLine(action.line) },
          ...state.slice(action.index + 1),
        ]
      }
      return state

    case ACTION_TYPES.REMOVE:
      if (action.index >= 0 && action.index < state.length) {
        return [
          ...state.slice(0, action.index),
          ...state.slice(action.index + 1),
        ]
      }
      return state

    case ACTION_TYPES.UNSET_MAIN_INGREDIENT:
      if (action.index >= 0 && action.index < state.length) {
        const ingredientAtIndex = state[action.index]
        if (isIngredientLineWithMainIngredient(ingredientAtIndex)) {
          const { mainIngredient, ...rest } = ingredientAtIndex
          return [
            ...state.slice(0, action.index),
            { ...rest },
            ...state.slice(action.index + 1),
          ]
        }
      }
      return state

    case ACTION_TYPES.SET_MAIN_INGREDIENT:
      if (action.index >= 0 && action.index < state.length) {
        return [
          ...state.slice(0, action.index),
          { ...state[action.index], mainIngredient: action.mainIngredient },
          ...state.slice(action.index + 1),
        ]
      }
      return state

    default:
      return state
  }
}
