import { MonthlySeasonScore } from './season'

export type MainIngredient = {
  _id: string
  name: string
  seasonality?: MonthlySeasonScore
  recipe?: string
  aisle?: string
}

export interface MainIngredientDict {
  [_id: string]: MainIngredient
}

/**
 * Is the main ingredient also a recipe?
 * @category mainIngredient
 */
export const isMainIngredientNestedRecipe = (
  ingredient: MainIngredient
): boolean => Boolean(ingredient.recipe)

export type UnsavedMainIngredient = Omit<MainIngredient, '_id'>

/**
 * Is main ingredient saved in database (aka not new, not unsaved)?
 * @category mainIngredient
 */
export function isSavedIngredient(
  i: MainIngredient | UnsavedMainIngredient
): i is MainIngredient {
  return (i as MainIngredient)._id !== undefined
}
